<template>
  <div class="card borderRadius" v-show="isShowItem">
    <div
      class="card-content borderRadius navigate dragicon"
      :id="item._id"
    >
      <div
        @click="
          openItem(index);
          removeSelected();
        "
        :id="'itemimg' + item._id"
        class="itemimg"
      >
        <FeatureImage
          :item="item"
          :attachmentView="attachmentView"
          :collection="collection"
          :thumbSize="{width:600,height:0}"
          :borderRadius="true"
          :bottomRadius="
            properties.display.fields.title ||
            properties.display.fields.description
          "
          :key="
            'galleryFeature' + item._rev + properties.display.fields.noThumbnail
          "
          @hasImage="hasImage"
          class="gimage"
          :showNoThumbnail="false"
        />
      </div>
      <div
        @click="
          openItem(index);
          removeSelected();
        "
      >
        <h4 class="pa-2" v-if="properties.display.fields.title && item.title">
          {{ item.title }}
        </h4>
        <p
          class="pa-2"
          v-if="properties.display.fields.description && item.body"
        >
          <markdown-it-vue
            class="md-body"
            :content="item.body"
            :collection="collection"
          />
        </p>
      </div>
      <v-btn
        small
        v-show="comments"
        v-if="properties.display.fields.commentCount"
        color="grey"
        class="comment-button"
        title="Comments"
        @click=""
      >
        <div>
          <v-icon class="primary--text">mdi-comment-text-outline</v-icon
          ><span class="pl-1 subtitle-1">{{ comments }}</span>
        </div>
      </v-btn>
      <v-btn
        x-small
        fab
        color="grey"
        class="download-button"
        title="Download"
        :href="firstImage?utils.getLink(firstImage):''"
      >
        <v-icon>mdi-download</v-icon>
      </v-btn>
      <v-btn
        v-show="authorization.update"
        x-small
        fab
        color="grey"
        class="float-button"
        @click="openItemEditor(item)"
        title="Edit"
      >
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
    </div>
  </div>
</template>
<script>
import { View } from '@/collections'
import Utility from '@/components/common/utils.js'
import FeatureImage from '@/components/FeatureImage.vue'
export default {
  name: 'GalleryCard',
  components: { FeatureImage },
  props: {
    item: Object,
    properties: Object,
    index: Number,
    view: Object,
    authorization: Object,
    collection: Object
  },
  data () {
    return {
      utils: Utility,
      commentView: null,
      attachmentView: null,
      showItem: false,
      firstImage: null
    }
  },
  async created () {
    this.attachmentView = await new View(this.collection).open({
      of: { attached_to: this.item._id }
    })
    this.commentView = await new View(this.collection).open({
      of: { container: this.item._id, type: 'comment' }
    })
  },
  beforeDestroy () {
    if (this.commentView !== null) this.commentView.close()
    if (this.attachmentView !== null) this.attachmentView.close()
  },
  mounted () {
  },
  computed: {
    isShowItem () {
      if (!this.properties.display.fields.noThumbnail) {
        return this.showItem
      }

      return true
    },
    comments () {
      return this.commentView !== null ? this.commentView.items.length : 0
    }
  },
  methods: {
    openItem (index) {
      this.$emit('openItem', index)
    },
    openItemEditor (item) {
      this.$emit('openItemEditor', item)
    },
    removeSelected () {
      document.getElementById(this.item._id).classList.remove('selected')
    },
    hasImage (val) {
      this.showItem = val.hasImage
      this.firstImage = val.item
    }
  },
  watch: {},
  beforeDestroy () {
    if (this.commentView !== null) this.commentView.close()
  }
}
</script>

<style scoped>
.theme--light .card {
  background: #f5f5f5;
}
.theme--dark .card {
  background: #525252;
}
.card {
  position: relative;
  padding: 0px;
  margin:5px 0;
  width: 100%;
  cursor: pointer;
  display: inline-block;
  min-height: 100px;
  overflow: hidden;
}
img {
  display: block;
  width: 100%;
}
.card:hover .float-button {
  display: block;
}
.float-button {
  display: none;
  position: absolute;
  top: 10px;
  right: 50px;
}
.card:hover .download-button {
  display: flex;
}
.download-button {
  display: none;
  position: absolute;
  top: 10px;
  right: 10px;
}
.comment-button {
  position: absolute;
  top: 10px;
  left: 10px;
}
.itemimg {
  transition: height 0.2s ease;
  -webkit-transition: height 0.2s ease;
  -moz-transition: height 0.2s ease;
  -o-transition: height 0.2s ease;
}
.borderRadius {
  border-radius: 5px;
}
.theme--light .selected {
  outline: 3px solid rgb(21, 101, 192, 0.4) !important;
}
.theme--dark .selected {
  outline: 3px solid rgb(239, 108, 0, 0.4) !important;
}
</style>
