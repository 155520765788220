<template>
  <v-menu
    bottom
    offset-y
    :close-on-click="true"
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on }">
      <v-btn :disabled="disabled" text small tile v-on="on" title="Move item" block class="left-btn d-inline-block">
        <v-icon class="mr-2">mdi-file-send</v-icon> Move item
      </v-btn>
    </template>
    <small class="mx-1">Move to Where?</small>
    <v-text-field
      ref="searchbox"
      hide-details
      solo
      @input="searchSuggestion"
      v-model="searchText"
      class="pa-0 mb-1 mx-1"
      placeholder="Search"
    ></v-text-field>
    <div v-show="!dash.isEmpty(suggestions)" class="menu-body">
      <div v-for="(items, i) in suggestions" :key="i">
        <div class="item-subheading pl-2 ma-1">{{ utils.ucfirst(i) }}</div>
        <v-divider />
        <v-list dense class="pa-0 ma-0">
          <template v-for="item in items">
            <v-list-item :key="item._id" @click="moveItem(item)">
              <visual-tooltip :item="item">
                <v-list-item-content>
                  <v-list-item-title class="itemtext">
                    <visual-indicator :type="item.type" />{{ item.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </visual-tooltip>
            </v-list-item>
          </template>
        </v-list>
      </div>
    </div>
  </v-menu>
</template>

<script>
import { View, Collection } from '@/collections'
import Utility from '@/components/common/utils.js'
import lodash from 'lodash'
import VisualTooltip from './VisualTooltip.vue'

export default {
  name: 'MoveItemButton',
  props: ['collection', 'item', 'repo', 'disabled'],
  data: function () {
    return {
      utils: Utility,
      searchText: '',
      dash: lodash,
      suggestions: {}
    }
  },
  computed: {},
  created () {},
  methods: {
    searchSuggestion () {
      const suggestions = this.repo.index.search(this.searchText, {
        filter: (result) => result['type'] === 'view',
        prefix: true
      })
      console.debug('Get search result', this.searchText, suggestions)
      this.suggestions = lodash.groupBy(suggestions, '@dbid')
      delete this.suggestions.undefined
      delete this.suggestions['']
    },
    async moveItem (destinationView) {
      const collection = new Collection(this.repo, destinationView['@dbid'])
      await collection.open()
      const view = await new View(collection).open(destinationView.id)
      const prompt = `Move this item to "${collection.info.title}" collection "${destinationView.title}" view.
      <br/><br/> <strong>Are you sure?</strong>`
      const res = await this.$confirm(prompt, {
        title: `Move Item`,
        buttonSaveText: `Move Item`,
        buttonDiscardText: ''
      })
      if (res === 1) {
        await view.drop(this.item)
        var successprompt = `This item has been moved to "${collection.info.title}" collection "${destinationView.title}" view.`
        await this.$confirm(successprompt, {
          title: `Moved Item`,
          buttonSaveText: `Ok`,
          buttonDiscardText: '',
          buttonCancelText: ''
        })
      }
      await view.close()
      await collection.close()
    }
  },
  components: { VisualTooltip }
}
</script>
<style scoped>
@media (min-width: 320px) and (max-width: 480px) {
  .closedrawer > * {
    flex: 1 1 auto;
  }
  .opendrawer > * {
    flex: 1 1 auto;
  }
}
.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
  background-color: #b6b3b3 !important;
}
.theme--light .v-menu__content--fixed {
  background: rgba(221, 220, 220, 0.8) !important;
}
.theme--dark .v-menu__content--fixed {
  background: rgba(0, 0, 0, 0.8) !important;
}
.menu-body {
  width: 215px;
  max-height: 60vh;
  min-height: 1px;
  overflow-y: auto;
}
</style>
