<template>
  <section>
    <DragDropFile :editor="$refs.fileuploader"  class="cover" :authorization="authorization"></DragDropFile>
    <div class="cards">
        <draggable
          :disabled="!orderable || !authorization.update"
          :list="items"
          handle=".dragicon"
          ghost-class="ghost"
          group="itemgroup"
          class="list-group"
          :animation="200"
          :delay="100"
          :delayOnTouchOnly="true"
          :touchStartThreshold="1"
          @change="orderChange($event)"
          v-bind="dragOptions"
        >
          <template v-for="(item, key) in items">
            <GalleryCard
              :key="'gallery' + item._rev"
              :item="item"
              :properties="properties"
              :view="view"
              v-on:openItem="openCarousel"
              v-on:openItemEditor="openDetail"
              :index="key"
              :authorization="authorization"
              :collection="collection"
            ></GalleryCard>
          </template>
        </draggable>
    </div>
    <Carousel
      :visible="galleryDialog"
      @close="galleryDialog = false"
      :view="view"
      :items="items"
      :collection="collection"
      :properties="properties"
      :itemIndex="carouselIndex"
      :index.sync="carouselIndex"
      :authorization="authorization"
      v-on:openItemEditor="openDetail"
    />
    <FileUploader
      ref="fileuploader"
      :collection="collection"
      :multipleAttachment="true"
      :multipleItem="true"
      :editFileTitle="true"
      :view="view"
    />
    <!-- This portion is for big "+" add item button -->
    <div
      class="d-print-none"
      :class="editordrawer ? 'opendraweradd' : 'closedraweradd'"
      v-if="perspective != 'views'"
      v-show="authorization.update"
    >
      <v-btn fab dark color="primary" @click="addNewInlineItem()">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </div>
  </section>
</template>

<script>
import { View } from '@/collections'
import GalleryCard from '@/components/layouts/GalleryCard.vue'
import Carousel from '@/components/Carousel.vue'
import DragDropFile from '@/components/fileagent/DragDropFile.vue'
import FileUploader from '@/components/FileUploader.vue'

export default {
  name: 'Gallery',
  components: {
    FileUploader,
    DragDropFile,
    Carousel,
    GalleryCard
  },
  props: {
    collection: Object,
    perspective: String,
    msg: String,
    authorization: Object,
    collectionID: String,
    account: String,
    selectedView: Object,
    properties: {
      type: Object,
      default: function () {
        return {
          display: {
            fields: {
              title: false,
              description: false,
              noThumbnail: false,
              commentCount: false
            }
          },
          details: {
            fields: { title: false, description: false, comment: false }
          }
        }
      }
    },
    editordrawer: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      view: null,
      newItem: '',
      galleryDialog: false,
      carouselIndex: 0,
      window: {
        width: 0,
        height: 0
      }
    }
  },
  computed: {
    dragOptions () {
      return {
        scroll: true,
        scrollSensitivity: 100,
        scrollSpeed: 10
      }
    },
    items: function () {
      if (this.view === null) return
      if (this.orderable) return this.view.items
      var items = [...this.view.items]
      return items.sort(function (a, b) {
        var firstEffectiveDate = new Date(a.effectiveAt).getTime()
        var nextEffectiveDate = new Date(b.effectiveAt).getTime()
        if (isNaN(firstEffectiveDate)) {
          // if no first value then set compare date to effective date
          firstEffectiveDate = new Date(a.createdAt).getTime()
        }
        if (isNaN(nextEffectiveDate)) {
          // if no second value then set compare date to effective date
          nextEffectiveDate = new Date(b.createdAt).getTime()
        }
        return nextEffectiveDate - firstEffectiveDate
      })
    },
    maxWidth: function () {
      switch (this.$vuetify.breakpoint.name) {
        case 'lg':
          return this.window.width / 2.5
        case 'xl':
          return this.window.width / 1.5
        default:
          return 700
      }
    },
    orderable () {
      if (
        typeof this.selectedView !== 'undefined' &&
        (this.selectedView.orderable ||
          typeof this.selectedView.orderable === 'string')
      ) {
        return true
      }
      return false
    }
  },
  async mounted () {
    this.view = await new View(this.collection).open(this.perspective, {
      type: { $ne: 'heading' }
    })
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize)
  },
  created () {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  methods: {
    addNewInlineItem () {
      this.$refs.fileuploader.click()
    },
    openCarousel (index) {
      this.galleryDialog = true
      this.carouselIndex = index
    },
    // REVIEW never referenced needs to be deleted or updated for async confirmAndRemoveTree
    // removeItem(item) {
    //   this.collection.remove(item);
    // },

    openDetail (item) {
      this.$router.push({
        name: 'list',
        params: {
          account: this.account,
          collectionID: this.collectionID,
          perspective: item._id
        }
      })
    },
    handleResize () {
      this.window.width = window.innerWidth
      this.window.height = window.innerHeight
    },
    orderChange (event) {
      if (typeof event.moved !== 'undefined') {
        var element = event.moved.element
        var newIndex = event.moved.newIndex
        console.log('set order heading', element, newIndex)
        this.view.setOrder(element._id, newIndex)
      }
    }
  }
}
</script>

<style scoped>
.cards {
  column-count: 1;
  column-gap: 1em;
}
@media only screen and (min-width: 500px) {
  .cards {
    column-count: 2;
  }
}

@media only screen and (min-width: 700px) {
  .cards {
    column-count: 3;
  }
}

@media only screen and (min-width: 900px) {
  .cards {
    column-count: 4;
  }
}

@media only screen and (min-width: 1281px) {
  .cards {
    column-count: 4;
  }
}
@media only screen and (min-width: 1441px) {
  .cards {
    column-count: 5;
  }
}
.closedraweradd {
  position: fixed;
  z-index: 1;
  right: 20px;
  bottom: 15px;
}
.opendraweradd {
  position: fixed;
  z-index: 1;
  right: 20px;
  bottom: 15px;
}
</style>
