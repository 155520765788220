<template>
  <v-menu
    bottom
    offset-y
    :close-on-click="true"
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        :disabled="disabled"
        v-show="!icon"
        icon
        class="d-inline-block opac-btn"
        v-on="on"
        title="Add attachment"
        @click="showAttachments"
      >
        <slot></slot>
      </v-btn>
      <v-btn
        :disabled="disabled"
        v-show="icon"
        icon
        fab
        x-small
        v-on="on"
        title="Add attachment"
        @click="showAttachments"
      >
        <v-icon class="mr-2">mdi-attachment</v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        v-for="(type, i) in attachmentTypes"
        :key="i"
        @click="openAttachmentType(type)"
        :title="type.title"
      >
        <v-list-item-title>
          <v-icon small>{{ type.icon }}</v-icon>
          {{ type.title }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
    <v-dialog
      v-model="attachemntDialog"
      persistent
      scrollable
      :max-width="selectedAttachmentType.type === 'link' ? '450px' : '900px'"
    >
      <v-card v-if="selectedAttachmentType.type === 'link'">
        <v-card-text style="max-height: 80vh" class="pa-0">
          <v-row class="mb-6 ma-2" justify="center" no-gutters>
            <v-col cols="12">
              <v-text-field
                label="Url"
                v-model="url"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="reset()">Close</v-btn>
          <v-btn color="primary" @click="uploadLinks">Attach Link</v-btn>
        </v-card-actions>
      </v-card>

      <v-card v-else-if="selectedAttachmentType.type === 'file'">
        <v-card-text style="max-height: 80vh" class="pa-0">
          <v-row class="pa-2" justify="center" no-gutters>
            <FileAgent
              @sync="syncFileData"
              @select="filesSelected($event)"
              @delete="fileDeleted($event)"
              :multiple="true"
            />
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="reset()">Close</v-btn>
          <v-btn
            color="primary"
            :disabled="!filesDataForUpload.length"
            :loading="uploading"
            @click="uploadFiles()"
            >Attach {{ filesDataForUpload.length }} files</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-menu>
</template>

<script>
import Spaces from '@/components/services/spaces.js'
import Utility from '@/components/common/utils.js'
import FileAgent from '@/components/fileagent/FileAgent.vue'
import { View } from '@/collections'
import imageFileToBase64 from '@/components/common/imgBase64'
import _attachment from '@/components/common/attachment.js'

export default {
  name: 'AttachmentButton',
  props: ['item', 'collection', 'icon', 'disabled'],
  data: function () {
    return {
      view: null,
      spaces: Spaces,
      dialog: false,
      attachemntDialog: false,
      url: '',
      title: '',
      response: null,
      validUrl: false,
      selectedAttachmentType: {},
      attachments: [],
      attachmentTypes: [
        { type: 'link', title: 'Attach Link', icon: 'mdi-link-variant' },
        { type: 'file', title: 'Attach File', icon: 'mdi-file' }
      ],
      filesData: [],
      filesDataForUpload: [],
      utils: Utility,
      uploading:false
    }
  },
  components: { FileAgent },
  async created () {
    this.view = await new View(this.collection).open({
      of: { attached_to: this.item._id }
    })
  },
  methods: {
    showAttachments () {
      this.$emit('open', 'attachment')
    },
    reset () {
      this.attachemntDialog = false
      this.attachments = []
      this.selectedAttachmentType = {}
      this.uploading=false
    },
    syncFileData: function (value) {
      this.filesData = value
    },
    filesSelected: function (filesDataNewlySelected) {
      var validFilesData = filesDataNewlySelected
      this.filesDataForUpload = validFilesData
    },
    fileDeleted: function (fileData) {
      this.filesDataForUpload = this.filesDataForUpload.filter((f) => {
        return f.file != fileData
      })
    },
    openAttachmentType (type) {
      this.selectedAttachmentType = type
      this.attachemntDialog = true
    },
    async uploadLinks () {
      await _attachment.putUrl(this.view, this.item, this.url, this, false)
      this.attachemntDialog = false
      this.url = ''
      this.title = ''
      this.reset()
    },
    selectedFile (selFile) {
      var self = this
      var file = selFile.file
      if (self.utils.isImage(file.type)) {
        if (selFile.fileoption === 'small') {
          file = selFile.small
        } else if (selFile.fileoption === 'medium') {
          file = selFile.medium
        }
      }
      return file
    },
    async uploadFiles () {
      try {
        var self = this
        this.uploading=true
        for (var j = 0; j < self.filesDataForUpload.length; j++) {
          var attachment = self.selectedFile(self.filesDataForUpload[j])
          var thumbnails = self.filesDataForUpload[j].thumbnails
          var space_key = self.utils.uuidv4() + '_' + attachment.name

          var attachmentData = {
            type: 'file',
            space_key: space_key,
            title: attachment.name,
            name: attachment.name,
            content_type: attachment.type,
            attached_to: self.item._id,
            _id: Utility.uuidv4()
          }
          var r = await this.spaces.uploadFile(attachment, attachmentData, space_key)
          if (r.status == 201) { await self.view.put(attachmentData) } else { this.$root.vtoast.show({ message: 'Fail to upload image. Try again later.' }) }
        }
      } catch (err) {
        this.$root.vtoast.show({ message: 'Fail to upload image. Try again later.' })
        this.uploading=false
      }
      self.filesDataForUpload = []
      self.filesData = []
      self.attachemntDialog = false
      this.reset()
    }
  }
}
</script>
<style scoped>
@media (min-width: 320px) and (max-width: 480px) {
  .closedrawer > * {
    flex: 1 1 auto;
  }
  .opendrawer > * {
    flex: 1 1 auto;
  }
}
.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
  background-color: #b6b3b3 !important;
}
</style>
