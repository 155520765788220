var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"bottom":"","offset-y":"","close-on-click":true,"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"left-btn d-inline-block",attrs:{"disabled":_vm.disabled,"text":"","small":"","block":"","tile":"","title":"Add tags"}},on),[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-tag-plus")]),_vm._v("Add tags ")],1)]}}])},[_c('v-list',{staticClass:"pa-0 ma-0"},[_c('v-list-item',{staticClass:"pa-0 ma-0"},[(_vm.tags && _vm.tags.length == 0 && !_vm.authorization.update)?_c('div'):_c('div',{staticStyle:{"width":"250px"}},[_c('v-combobox',{attrs:{"hide-details":true,"full-width":"","filter":_vm.filter,"hide-no-data":!_vm.search,"items":_vm.items,"search-input":_vm.search,"hide-selected":"","label":"Search for an option","multiple":"","small-chips":"","solo":""},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event},"change":function($event){return _vm.setTags()}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('span',{staticClass:"subheading mr-2"},[_vm._v("Create")]),_c('v-chip',{attrs:{"color":"grey lighten-1","label":"","small":""}},[_vm._v(_vm._s(_vm.search))])],1)]},proxy:true},{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var parent = ref.parent;
var selected = ref.selected;
return [_c('v-chip',_vm._b({attrs:{"color":"grey lighten-1","input-value":selected,"label":"","small":""}},'v-chip',attrs,false),[_c('span',{staticClass:"pr-2"},[_vm._v(_vm._s(item))]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return parent.selectItem(item)}}},[_vm._v("mdi-close")])],1)]}},{key:"item",fn:function(ref){
var index = ref.index;
var item = ref.item;
return [(_vm.editing === item)?_c('v-text-field',{attrs:{"autofocus":"","flat":"","background-color":"transparent","hide-details":"","solo":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.edit(index, item, _vm.editingtext)}},model:{value:(_vm.editingtext),callback:function ($$v) {_vm.editingtext=$$v},expression:"editingtext"}}):_c('v-chip',{attrs:{"color":"grey lighten-1","dark":"","label":"","small":""}},[_vm._v(_vm._s(item))]),_c('v-spacer'),_c('v-list-item-action',{on:{"click":function($event){$event.stopPropagation();}}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.edit(index, item, _vm.editingtext)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.editing !== item ? "mdi-pencil" : "mdi-check"))])],1)],1)]}}]),model:{value:(_vm.tags),callback:function ($$v) {_vm.tags=$$v},expression:"tags"}})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }