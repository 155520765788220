<!-- Component to edit an item -->

<template>
  <section v-if="dialog">
    <div v-if="item !== null">
      <v-btn
        title="Cancel"
        v-show="authorization.update"
        icon
        class="d-inline-block opac-btn ml-8 mt-2"
        @click.native="save(false)"
        v-if="isMobile"
      >
        <v-icon>mdi-arrow-collapse</v-icon>
      </v-btn>
      <div class="toparea-none">
        <div
          v-if="item._id && utils.featureIsEnabled(currentUser)"
          :class="item.tags && item.tags.length > 0 ? 'topareatag' : ''"
        >
          <v-chip
            v-for="tag in item.tags"
            class="ma-1"
            color="grey lighten-1"
            small
            :key="tag"
            >{{ tag }}</v-chip
          >
        </div>
      </div>
      <div :class="editordrawer ? 'opendrawersave' : 'closedrawersave'">
        <v-btn
          v-if="isMobile && mode == 'new'"
          icon
          :color="changeSave ? 'primary' : ''"
          @click.native="save(false)"
          v-show="authorization.update"
          class="m-t-8"
        >
          <v-icon>mdi-check</v-icon>
        </v-btn>
      </div>
      <div :class="editordrawer ? 'opendrawer' : 'closedrawer'">
        <v-btn
          v-if="!isMobile && mode == 'new'"
          :color="changeSave ? 'primary' : ''"
          title="Save"
          icon
          v-show="authorization.update"
          class="d-inline-block opac-btn"
          @click.native="save(false)"
        >
          <v-icon>mdi-check</v-icon>
        </v-btn>
        <AttachmentButton
          :disabled="!authorization.update"
          v-if="item._id"
          :item="item"
          :collection="collection"
          :key="'attachmentButton' + item._rev"
          @open="showAttachments()"
        >
          <div>
            <v-icon class="small-icon">mdi-attachment</v-icon
            ><span class="small-badge" v-show="attachments">{{
              attachments
            }}</span>
          </div>
        </AttachmentButton>
        <v-btn
          v-if="item._id && comments"
          :disabled="!authorization.comment"
          title="Comment"
          icon
          class="d-inline-block opac-btn ml-2"
          @click="toggleCollapseComment = !toggleCollapseComment"
        >
          <v-icon>mdi-comment-text-outline</v-icon
          ><span class="item-font">{{ comments }}</span>
        </v-btn>
        <v-btn
          v-if="item._id && !comments"
          :disabled="!authorization.comment"
          title="Comment"
          icon
          class="d-inline-block opac-btn ml-2"
          @click="toggleCollapseComment = !toggleCollapseComment"
        >
          <v-icon>mdi-comment-plus-outline</v-icon>
        </v-btn>
        <v-menu bottom offset-y>
          <template v-slot:activator="{ on }">
            <v-btn icon class="d-inline-block opac-btn" v-on="on" title="More">
              <v-icon>mdi-dots-horizontal</v-icon>
            </v-btn>
          </template>
          <v-list dense class="ma-0 pa-0">
            <v-list-item class="ma-0 pa-0" v-if="item._id">
              <MoveItemButton
                :disabled="!authorization.update"
                :key="'moveItemButton' + item._rev"
                :repo="repo"
                :collection="collection"
                :item="item"
              ></MoveItemButton>
            </v-list-item>
            <v-list-item
              class="ma-0 pa-0"
              v-if="utils.featureIsEnabled(currentUser)"
            >
              <AssigneeButton
                :disabled="!authorization.update"
                :item="item"
                :collection="collection"
                :key="'assigneeButton' + item._rev"
                v-on:setAssignee="setAssignee"
                :collectionMembersView="collectionMembersView"
              ></AssigneeButton>
            </v-list-item>
            <v-list-item
              class="ma-0 pa-0"
              v-if="utils.featureIsEnabled(currentUser)"
            >
              <TagAgent
                :disabled="!authorization.update"
                :authorization="authorization"
                v-on:setTags="setTags"
                :assignTags="item.tags"
                :icon="false"
              />
            </v-list-item>
            <v-list-item
              class="ma-0 pa-0"
              v-if="
                item._id &&
                isOwner &&
                collection.info.defaultPage &&
                item._id == collection.info.defaultPage
              "
            >
              <v-btn
                title="Edit area"
                text
                small
                tile
                block
                class="left-btn d-inline-block"
                @click="openEditCollection()"
              >
                <v-icon class="mr-2">mdi-pencil</v-icon>Edit area
              </v-btn>
            </v-list-item>
            <v-list-item class="ma-0 pa-0" v-if="item._id && isOwner">
              <v-btn
                title="Share"
                text
                small
                tile
                block
                class="left-btn d-inline-block"
                @click="openShareDialog()"
              >
                <v-icon class="mr-2">mdi-share-variant</v-icon> Share
              </v-btn>
            </v-list-item>
            <v-list-item class="ma-0 pa-0" v-if="item._id">
              <v-btn
                title="Delete"
                text
                small
                tile
                block
                class="left-btn d-inline-block"
                @click="remove"
                :disabled="!authorization.update"
              >
                <v-icon color="red" class="mr-2">mdi-close-circle</v-icon>
                Delete
              </v-btn>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-btn
          title="Cancel"
          v-show="authorization.update"
          icon
          class="d-inline-block opac-btn ml-4"
          @click.native="save(false)"
          v-if="!isMobile"
        >
          <v-icon>mdi-arrow-collapse</v-icon>
        </v-btn>
        <v-btn
          icon
          class="d-inline-block opac-btn"
          @click="showHideInspector()"
          title="Show/Hide inspector"
          v-if="!editordrawer"
        >
          <v-icon>mdi-information</v-icon>
        </v-btn>
      </div>
      <v-navigation-drawer
        v-model="editordrawer"
        app
        clipped
        right
        v-if="item !== null"
        mobile-breakpoint="769"
        :disable-resize-watcher="true"
        :touchless="true"
        @input="checkDrawerState"
        height="100%"
      >
        <template v-slot:prepend>
          <div class="text-center ma-2 align-self-center justify-center d-flex">
            <span class="text-h6">Item Details</span>
            <v-btn
              icon
              @click.stop="showHideInspector"
              class="editor-close d-inline-block"
              v-if="editordrawer"
              title="Show/Hide inspector"
            >
              <v-icon>mdi-information</v-icon>
            </v-btn>
          </div>
        </template>
        <v-row>
          <v-col cols="12">
            <div class="ma-4">
              <div class="mt-2 mb-2 subtitle-2 text-left">
                <span class="d-block font-weight-regular" v-if="item.createdBy">
                  <b>Author:</b>
                  {{ userInfoAuthor.display }}
                </span>
                <span class="d-block font-weight-regular" v-if="item.createdAt">
                  <b>Created:</b>
                  {{
                    utils.formatDateTime(item.createdAt, "DD/MMM/YYYY hh:mm A")
                  }}
                </span>
                <span class="d-block font-weight-regular" v-if="item.updatedAt">
                  <b>Updated:</b>
                  {{
                    utils.formatDateTime(item.updatedAt, "DD/MMM/YYYY hh:mm A")
                  }}
                </span>
                <span
                  class="d-block font-weight-regular"
                  v-if="item._id && isOwner"
                >
                  <b>EffectivelyAt:</b>
                  <v-menu
                    v-model="menu"
                    :close-on-content-click="false"
                    offset-y
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on" class="d-inline ml-1">
                        <span v-if="item.effectiveAt">
                          {{
                            utils.formatDateTime(
                              item.effectiveAt,
                              "DD/MMM/YYYY hh:mm A"
                            )
                          }}
                        </span>
                        <span v-else class="text-decoration-underline"
                          >Select a date</span
                        >
                      </div>
                    </template>
                    <v-date-picker
                      v-model="item.effectiveAt"
                      @input="
                        menu = false;
                        save(true);
                      "
                    ></v-date-picker>
                  </v-menu>
                </span>
                <div>
                  <CollectionDefaultPage
                    v-if="item._id && isOwner"
                    :collection="collection"
                    :item="item"
                    :repo="repo"
                  />
                </div>
                <span class="d-block font-weight-regular" v-if="item.assignee">
                  <b>Assignee:</b>
                  <v-avatar color="orange accent-1" size="30" class="ml-2 mr-2">
                    <AsyncAvatarImage
                      :username="item.assignee"
                      :collection="collection"
                    />
                  </v-avatar>
                  <span v-if="item.assignee">
                    {{ userInfoAssignee.display }}</span
                  >
                </span>
              </div>
            </div>
            <div class="ma-4 text-center">
              <div class="mt-2">
                <FeatureImage
                  :attachmentView="attachmentView"
                  :item="item"
                  :thumbSize="{ width: 600, height: 0 }"
                  :borderRadius="true"
                  class="thumbimg gimage"
                  @imageerror="OnImageError"
                  :collection="collection"
                  :key="'featureInspector' + item._rev"
                />
              </div>
            </div>
          </v-col>
        </v-row>
      </v-navigation-drawer>
    </div>
    <v-card
      flat
      style="background-color: transparent"
      class="ma-0 pa-0"
      tile
      v-if="item !== null"
    >
      <div class="maincontainer mt-1">
        <DragDropFile
          :item="item"
          :editor="$refs.fileuploader"
          :authorization="authorization"
          :allowdrop="authorization.update"
          :showText="false"
          @dropText="dropText"
          ref="dragdrop"
        >
          <v-row no-gutters class="page-box">
            <v-col cols="12" class="pa-0 ma-0">
              <div class="d-flex">
                <v-textarea
                  label="Title here..."
                  auto-grow
                  solo
                  rows="1"
                  row-height="15"
                  flat
                  @blur="quickSave('title')"
                  ref="itemTitle"
                  style="font-size: 1.4rem"
                  class="custom-input-multiline font-weight-bold h1 text-center"
                  background-color="transparent"
                  :readonly="!authorization.update"
                  v-model="item.title"
                  hide-details
                  :class="!isMobile ? 'margin-big' : ''"
                />
              </div>
              <div class="d-flex"></div>
              <v-col cols="12" class="ma-0 pa-0">
                <div class="text-center">
                  <div class="mt-2">
                    <FeatureImage
                      :attachmentView="attachmentView"
                      :item="item"
                      :thumbSize="{ width: 600, height: 0 }"
                      :borderRadius="true"
                      class="thumbimg gimage"
                      @imageerror="OnImageError"
                      :collection="collection"
                      :key="'feature' + item._rev"
                    />
                  </div>
                </div>
              </v-col>
              <!-- Description area -->
              <div class="mt-2 d-flex">
                <text-area
                  :authorization="authorization"
                  v-model="item.body"
                  :autoheight="false"
                  class="texteditor"
                  @attachment="syncAttachment"
                  :collection="collection"
                  @blur="quickSave('body')"
                />
              </div>
              <Attachment
                :parentView="view"
                :authorization="authorization"
                ref="attachment"
                :item="item"
                :collection="collection"
                :key="'itemEditorAttachment' + item._rev"
                v-if="item._id"
              />
              <InlineComment
                v-if="toggleCollapseComment"
                :lineThree="false"
                :collection="collection"
                ref="comment"
                :item="item"
                :key="'collapse_comment' + item._rev"
                :authorization="authorization"
                :showCommentText="toggleCollapseComment"
                class="mb-9 pb-9"
                :details="true"
              />
            </v-col>
          </v-row>
        </DragDropFile>
      </div>
    </v-card>
    <FileUploader
      ref="fileuploader"
      :collection="collection"
      :multipleAttachment="true"
      :multipleItem="true"
      :view="view"
    />
  </section>
</template>
<script>
import Attachment from '@/components/Attachment.vue'
import AttachmentButton from '@/components/AttachmentButton.vue'
import AssigneeButton from '@/components/AssigneeButton.vue'
import MoveItemButton from '@/components/MoveItemButton.vue'
import InlineComment from '@/components/InlineComment.vue'
import Spaces from '@/components/services/spaces.js'
import Utils from '@/components/common/utils.js'
import AsyncAvatarImage from '@/components/AsyncAvatarImage.vue'
import TagAgent from '@/components/TagAgent.vue'
import lodash from 'lodash'
import TextArea from '@/components/common/markdownit/text-area.vue'
import CollectionDefaultPage from '@/components/CollectionDefaultPage.vue'
import AsyncImage from '@/components/AsyncImage.vue'
import { View, Auth } from '@/collections'
import FeatureImage from '@/components/FeatureImage.vue'
import _attachment from '@/components/common/attachment.js'
import DragDropFile from '@/components/fileagent/DragDropFile.vue'
import FileUploader from '@/components/FileUploader.vue'
export default {
  name: 'ItemEditor',
  data: function () {
    return {
      item: null,
      dialog: false,
      utils: Utils,
      fileData: [],
      spaces: Spaces,
      error: '',
      showCommentText: false,
      olditem: null,
      view: null,
      userInfoAuthor: {},
      userInfoAssignee: {},
      menu: false,
      commentView: null,
      attachmentView: null,
      fab: false,
      imageError: false,
      attachmentInfo: [],
      editordrawer: true,
      toggleCollapseComment: false
    }
  },
  props: {
    editing: {
      type: Boolean,
      default: false
    },
    viewInbox: {
      type: Object,
      default: null
    },
    collection: Object,
    authorization: Object,
    perspective: String,
    collectionID: String,
    mode: String,
    account: String,
    collectionMembersView: Object,
    isOwner: {
      type: Boolean,
      default: false
    },
    repo: Object,
    currentUser: Object
  },
  components: {
    Attachment,
    InlineComment,
    AttachmentButton,
    AssigneeButton,
    MoveItemButton,
    AsyncAvatarImage,
    TagAgent,
    TextArea,
    CollectionDefaultPage,
    FeatureImage,
    DragDropFile,
    FileUploader
  },
  beforeDestroy () {
    this.saveQuit()
    if (this.commentView !== null) this.commentView.close()
    if (this.attachmentView !== null) this.attachmentView.close()
    document.removeEventListener('keydown', this.keyListener)
    document.removeEventListener('paste', this.onPaste)
  },
  async created () {
    if (
      typeof this.perspective !== 'undefined' &&
      this.perspective === '_inbox'
    ) {
      Object.assign(this.$data, this.$options.data.apply(this))
      this.dialog = true
      this.view = this.viewInbox
      if (this.mode !== 'new') {
        var details = await this.collection.get(this.mode)
        this.item = Object.assign({}, details)
        this.olditem = Object.assign({}, this.item)
        this.getUserInfo(this.item)
      } else {
        this.focusInput()
        this.item = {}
        this.olditem = {}
      }
    } else if (
      typeof this.perspective !== 'undefined' &&
      this.perspective != 'views' &&
      this.perspective != ''
    ) {
      // if perspective "" then do not call view constructor or no need to open item editor
      Object.assign(this.$data, this.$options.data.apply(this))
      this.view = await new View(this.collection).open(this.perspective)
      var details = await this.collection.get(this.perspective)
      if (details.type == 'view' && this.mode == '') {
        this.dialog = false
      } else {
        this.dialog = true
        if (this.mode != 'new') {
          this.item = Object.assign({}, details)
          this.olditem = Object.assign({}, this.item)
          this.getUserInfo(this.item)
          this.commentView = await new View(this.collection).open({
            of: { container: this.item._id, type: 'comment' }
          })
          this.attachmentView = await new View(this.collection).open({
            of: { attached_to: this.item._id }
          })
        } else {
          this.focusInput()
          this.item = {}
          this.olditem = {}
        }
      }
    }
  },
  methods: {
    showAttachments (val) {
      this.$refs.attachment.focus()
    },
    focusInput () {
      this.$nextTick(() => {
        this.$refs.itemTitle.focus()
      })
    },
    openOn (item) {
      Object.assign(this.$data, this.$options.data.apply(this))
      this.item = Object.assign({}, this.item)
      this.olditem = Object.assign({}, this.item)
      this.dialog = true
    },

    async remove () {
      console.log('ItemEditor remove', this.item)
      const removed = await this.repo.removeItem(this.item._id, this)
      if (removed) {
        this.gotoMainView()
        this.dialog = false
      }
    },

    gotoMainView () {
      this.$router.go(-1)
    },
    syncAttachment (value) {
      for (var i = 0; i < value.length; i++) {
        this.attachmentInfo.push(value[i])
      }
    },
    async save (back) {
      if (this.mode == 'new') {
        await this.view.put(this.item)
      } else {
        if (this.item.effectiveAt) {
          this.item.effectiveBy = this.collection.info.account
        }
        if (this.authorization.update) {
          await this.collection.put(this.item)
        }
      }
      if (this.attachmentInfo.length > 0) {
        await _attachment.add(this.item, this.attachmentInfo, this.collection)
      }
      this.attachmentInfo = []
      if (!back) {
        this.dialog = false
        Object.assign(this.$data, this.$options.data.apply(this))
        this.gotoMainView()
      }
    },
    async saveQuit () {
      if (this.changeSave) {
        await this.save(true)
        this.dialog = false
        this.fileData = []
        Object.assign(this.$data, this.$options.data.apply(this))
      }
    },
    async cancel (back) {
      this.dialog = false
      this.fileData = []
      this.attachmentInfo = []
      Object.assign(this.$data, this.$options.data.apply(this))
      if (!back) this.gotoMainView()
    },
    focusComment () {
      this.showCommentText = true
      this.$refs.comment.focus()
    },
    removeUploadPic: function () {
      this.collection.removeAttachment(this.item)
    },
    setAssignee (member) {
      this.item = Object.assign({}, this.item, { assignee: member })
      this.save(true)
    },
    setTags (tags) {
      this.item = Object.assign({}, this.item, { tags: tags })
      this.save(true)
    },
    async getUserInfo (item) {
      this.userInfoAuthor = await this.utils.getUserDetails(
        this.collection,
        this.item.createdBy
      )
      this.userInfoAssignee = await this.utils.getUserDetails(
        this.collection,
        this.item.assignee
      )
    },
    openShareDialog () {
      this.$parent.openMembershipDialog()
    },
    openEditCollection () {
      this.$parent.$parent.$parent.$parent.openEditCollection(
        this.collection.info
      )
    },
    changeDrawerState () {
      this.$emit('updateEditordrawer', this.editordrawer)
    },
    checkDrawerState () {
      this.$emit('updateEditordrawer', this.editordrawer)
    },
    showHideInspector () {
      this.editordrawer = !this.editordrawer
      localStorage.setItem('inspectorState', this.editordrawer)
    },
    OnImageError () {
      this.imageError = true
    },
    async keyListener (e) {
      if (e.key === '~' && (e.ctrlKey || e.metaKey) && this.item !== null) {
        e.preventDefault() // present browser event trigger
        console.log(this.item)
      } else if (e.key === 'Escape') {
        if (!this.changeSave) {
          this.cancel(false)
          return
        }
        const res = await this.$confirm(
          'There are some unsaved changes. Do you want to save',
          {
            title: `Save`,
            buttonSaveText: `Save`,
            buttonDiscardText: 'Discard',
            buttonCancelText: 'Cancel'
          }
        )
        if (res == 1) {
          this.save(false)
        } else if (res == 0) {
          this.cancel(false)
        }
      } else if (
        e.keyCode === 37 &&
        e.target.type !== 'textarea' &&
        e.target.type !== 'text'
      ) {
        // arrow left
        if (!this.changeSave) {
          this.cancel(false)
        }
      }
    },
    async quickSave (key) {
      if (this.olditem[key] !== this.item[key] && this.item._id) {
        console.log('save ' + key)
        await this.view.put(this.item)
        if (key == 'body' && this.attachmentInfo.length > 0) {
          await _attachment.add(
            this.item,
            this.attachmentInfo,
            this.collection
          )
          this.attachmentInfo = []
        }

        this.olditem = Object.assign({}, this.item)
      }
    },
    async dropText (text) {
      if (this.utils.validateUrl(text)) {
        await _attachment.putUrl(this.attachmentView, this.item, text, this)
      }
    },
    onPaste (e) {
      console.log('paste called', e.clipboardData.files)
      if (
        this.authorization.update &&
        e.clipboardData.files.length > 0 &&
        e.target.type !== 'textarea' &&
        e.target.type !== 'text'
      ) {
        this.$refs.dragdrop.paste(e.clipboardData.files)
      }
    }
  },
  mounted () {
    document.addEventListener('keydown', this.keyListener)
    document.addEventListener('paste', this.onPaste)
    let authSpec = Auth.get()
    let inspectorState = localStorage.getItem('inspectorState')
    this.editordrawer = inspectorState
      ? JSON.parse(inspectorState)
      : !!authSpec
  },
  computed: {
    comments () {
      return this.commentView !== null ? this.commentView.items.length : 0
    },
    attachments () {
      return this.attachmentView !== null
        ? this.attachmentView.items.length
        : 0
    },
    isInbox () {
      if (this.perspective === '_inbox') {
        return true
      }
      return false
    },
    changeSave () {
      if (
        !lodash.isEqual(this.olditem, this.item) &&
        this.authorization.update
      ) {
        this.$emit('update:editing', true)
        return true
      }
      return false
    },
    newItem () {
      return this.item
    },
    isMobile () {
      return !!(
        this.$vuetify.breakpoint.name === 'sm' ||
        this.$vuetify.breakpoint.name === 'xs'
      )
    }
  }
}
</script>

<style scoped>
#foo {
  width: 200px;
}
.v-dialog > .v-card > .v-toolbar {
  position: sticky;
  top: 0;
  z-index: 999;
}
.v-subheader {
  height: auto;
  padding: 15px 15px;
}
.menu-btn {
  min-width: 180px !important;
  justify-content: left !important;
}
.author {
  display: flex !important;
}
@media only screen and (max-width: 768px) {
  .author {
    display: block !important;
    font-size: 12px;
  }
  .author-item {
    display: block !important;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .author-item-assignee {
    position: absolute;
    right: 20px;
    top: 75px;
  }
}

.itemtoolbar >>> .v-toolbar__content {
  padding: 4px 5px;
}

.opendrawer {
  position: absolute;
  z-index: 2;
  right: 10px;
  top: 8px;
}
.closedrawer {
  position: absolute;
  z-index: 2;
  right: 10px;
  top: 8px;
}
.opendrawersave {
  position: absolute;
  z-index: 2;
  right: 336px;
  top: 0;
}
.closedrawersave {
  position: absolute;
  z-index: 1;
  right: 80px;
  top: 0;
}

.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
  background-color: #b6b3b3 !important;
}
.closedraweradd {
  position: fixed;
  z-index: 1;
  right: 20px;
  bottom: 0;
}
.opendraweradd {
  position: fixed;
  z-index: 1;
  right: 20px;
  bottom: 0;
}
@media only screen and (max-width: 959px) {
  .closedrawersave {
    right: 10px;
  }
  .opendrawersave {
    right: 10px;
  }
}
.small-badge {
  font-size: 0.9375rem;
}
.small-icon {
  margin-right: 3px;
}
.itemimage {
  max-width: 100%;
  max-height: 200px;
  border-radius: 5px;
}
.thumbimg {
  max-width: 200px;
  border-radius: 5px;
  margin: 0 auto;
}
.editor-close {
  position: absolute;
  right: 10px;
  top: 7px;
}
.topareatag {
  margin-top: 60px !important;
}
.toparea {
  min-height: 30px;
  margin-top: 5px;
}
.maincontainer {
  margin-top: 30px;
}
.texteditor {
  padding: 5px 20px;
}
.margin-big {
  margin: 0 210px;
}
</style>
