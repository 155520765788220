<template>
  <section class="listview" id="itemlist">
    <div
      v-if="perspective === 'views'"
      :class="isMobile ? 'mt-5 mb-2 pt-5' : ''"
    >
      <h1 class="text-center my-2" style="font-size: 1.3rem">
        {{ collection.info.title }}
      </h1>
    </div>
    <div class="main" v-if="view !== null">
      <div v-show="headingSection" class="d-print-none mx-3">
        <div class="mt-2">
          <input
            autofocus
            tabindex="1"
            type="text"
            ref="headingTitle"
            v-model="headingTitle"
            placeholder="Heading title here..."
            class="custom-heading text-left smallcaps"
            @blur="saveHeading"
            @keydown.enter="saveHeading"
          />
        </div>
      </div>
      <draggable
        :animation="200"
        :delay="100"
        :delayOnTouchOnly="true"
        :touchStartThreshold="1"
        ghost-class="ghost"
        :disabled="!orderable || !authorization.update || groupBy !== 'heading'"
        :list="items"
        @change="orderChangeHeading($event)"
        handle=".headitem"
        group="heading"
        @start="dragStart"
        @end="dragEnd"
        :move="moved"
        v-bind="dragOptions"
      >
        <div
          v-for="(column, index, key) in items"
          :key="'column' + column.item._id"
        >
          <DragDropFile
            v-show="
              groupBy === 'none' &&
              perspective != 'views' &&
              authorization.update
            "
            :disabledrop="disabledrop"
            :column="column.item"
            :editor="$refs.fileuploader"
            :authorization="authorization"
            class="cover"
          >
          </DragDropFile>
          <BlogHeading
            @sync="highlightCurrentItem"
            :ref="'itemheadingref' + index"
            :headingIndex="index"
            :key="'itemheading' + column.item._id + column.item._rev"
            :column="column"
            :authorization="authorization"
            :collectionID="collectionID"
            :account="account"
            :isInbox="isInbox"
            :collection="collection"
            :perspective="perspective"
            :properties="properties"
            :groupBy="groupBy"
            :orderable="orderable"
            :view="view"
            :disabledrop.sync="disabledrop"
            :viewInbox="viewInbox"
            :selectedId.sync="selectedId"
            :print="print"
            :editormultiple="$refs.fileuploader"
            :headingIds="items"
          />
        </div>
      </draggable>
    </div>
    <FileUploader
      ref="fileuploader"
      :collection="collection"
      :multipleAttachment="true"
      :groupBy="groupBy"
      :view="perspective == 'views' ? viewInbox : view"
    />
    <!-- This portion is for big "+" add item button -->
    <div
      class="d-print-none"
      :class="editordrawer ? 'opendraweradd' : 'closedraweradd'"
      v-if="perspective != 'views'"
      v-show="authorization.update"
    >
      <v-btn fab dark color="primary" @click="addNewInlineItem()">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </div>
    <div v-if="perspective == '_inbox'">
      <div class="closedraweradd d-print-none">
        <v-btn fab dark color="primary" @click="addNewInlineItem()">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </div>
    </div>
  </section>
</template>

<script>
import { View, Auth } from '@/collections'
import Utility from '@/components/common/utils.js'
import FileUploader from '@/components/FileUploader.vue'
import BlogHeading from '@/components/layouts/BlogHeading.vue'
export default {
  name: 'BlogList',
  components: {
    FileUploader,
    BlogHeading,
    DragDropFile: () => import('@/components/fileagent/DragDropFile.vue')
  },
  props: {
    layout: String,
    editordrawer: {
      type: Boolean,
      default: false
    },
    print: {
      type: Boolean,
      default: false
    },
    viewInbox: {
      type: Object,
      default: null
    },
    collection: Object,
    selectedView: Object,
    perspective: String,
    msg: String,
    authorization: Object,
    collectionID: String,
    account: String,
    properties: {
      type: Object,
      default: function () {
        return {
          display: {
            fields: {
              displayFullDescription: false,
              inlineComment: false
            }
          }
        }
      }
    },
    collectionMembersView: Object
  },
  data: function () {
    return {
      currentItemIndex: -1,
      selectedId: '',
      selectedItemObj: null,
      hview: null,
      items: null,
      view: null,
      newItem: '',
      visibility: 'all',
      utils: Utility,
      headingSection: false,
      headingTitle: '',
      disabledrop: false
    }
  },
  computed: {
    dragOptions () {
      return {
        scroll: true,
        scrollSensitivity: 100,
        scrollSpeed: 10
      }
    },
    collectionName () {
      var originalName = this.collection.info.title
      return originalName
    },
    isMobile () {
      return !!(
        this.$vuetify.breakpoint.name === 'sm' ||
        this.$vuetify.breakpoint.name === 'xs'
      )
    },
    /**
     *read groupBy properties from view and enable/disable grouping
     */
    groupBy () {
      if (this.perspective === '_inbox') {
        return 'none'
      } else if (
        typeof this.selectedView !== 'undefined' &&
        (this.selectedView.groupBy ||
          typeof this.selectedView.groupBy === 'string')
      ) {
        return this.selectedView.groupBy
      }
      return 'none'
    },
    showOthers () {
      return 'above'
    },
    members () {
      return this.collectionMembersView !== null
        ? this.collectionMembersView.items.filter((x) => x.name)
        : []
    },
    /**
     *read orderable properties from view and enable/disable itemList dragging
     */
    orderable () {
      if (
        this.perspective === '_inbox' &&
        this.view !== null &&
        (this.view.orderable || typeof this.view.orderable === 'string')
      ) {
        return true
      } else if (
        typeof this.selectedView !== 'undefined' &&
        (this.selectedView.orderable ||
          typeof this.selectedView.orderable === 'string')
      ) {
        return true
      }
      return false
    },
    isInbox () {
      if (this.perspective === '_inbox') {
        return true
      }
      return false
    },
    hviewItems () {
      return this.hview !== null ? this.hview.items : null
    },
    viewItems () {
      return this.view !== null ? this.view.items : null
    }
  },
  beforeDestroy () {
    if (this.hview !== null) this.hview.close()
    if (this.view !== null) this.view.close()
    document.removeEventListener('keydown', this.nextItem)
    document.removeEventListener('keydown', this.keyListener)
  },
  async mounted () {
    if (this.perspective === '_inbox') {
      this.view = this.viewInbox
      this.items = await this.renderItems()
    } else {
      this.hview = await new View(this.collection).open(this.perspective, {
        type: 'heading',
        container: this.perspective
      })
      this.view = await new View(this.collection).open(this.perspective)
    }
    if (this.perspective !== 'views') {
      document.addEventListener('keydown', this.nextItem)
      // localStorage.removeItem('selected')
      // hilight previously selected item
      var self = this
      setTimeout(() => {
        self.highlightCurrentItem()
      }, 1000)
    }
    document.addEventListener('keydown', this.keyListener)
    var itemListDOM = document.getElementById('itemlist')
    if (itemListDOM !== null) {
      itemListDOM.focus()
    }
  },
  updated: function () {
    // this.highlightCurrentItem()
  },
  methods: {
    // add new item keyboard shortcut
    keyListener (e) {
      if ((e.key === 'n' && e.altKey) || (e.key === 'n' && e.metaKey)) {
        e.preventDefault() // present browser event trigger
        this.addNewInlineItem()
      } else if (
        (e.keyCode == 38 && e.altKey) ||
        (e.keyCode == 38 && e.metaKey)
      ) {
        this.orderItem('up', e)
      } else if (
        (e.keyCode == 40 && e.altKey) ||
        (e.keyCode == 40 && e.metaKey)
      ) {
        this.orderItem('down', e)
      }
    },
    // scroll page to selected item and set that item current index
    highlightCurrentItem () {
      this.$nextTick(() => {
        var sel = localStorage.getItem('selected')
        if (sel !== null) {
          var listItemDiv = document.querySelectorAll('.navigate')
          var arr = Array.prototype.slice.call(listItemDiv)
          var index = arr.findIndex((e) => e.id === sel)
          if (index === -1) {
            this.scrollPage(listItemDiv[this.currentItemIndex])
          } else {
            this.currentItemIndex = index
            this.scrollPage(listItemDiv[this.currentItemIndex])
          }
        }
      })
    },
    orderItem (direction, e) {
      e.preventDefault() // present browser event trigger
      // if (this.isItemFocused()) return
      console.log('order ' + direction)
      this.deselectItem()
      if (this.orderable && this.authorization.update) {
        this.arrowOrder(direction)
      } else if (this.groupBy !== 'none') this.arrowOrderGroup(direction)
    },
    // order item in group if orderable is disable
    arrowOrderGroup (direction) {
      var selectedItem = localStorage.getItem('selected')
      console.log('selected item to move', selectedItem)
      var doc = document.getElementById('itemsource' + selectedItem)
      var index = Number(doc.getAttribute('data-index'))
      var headercount = this.items.length
      this.setItemOrderGroup(direction, selectedItem, index)
    },
    setItemOrderGroup (direction, selectedItem, index) {
      var headingref = this.$refs['itemheadingref' + index][0]
      if (typeof headingref !== 'undefined') {
        var item = headingref.$refs['itemref' + selectedItem][0]
        var newheading = null
        var newindex = direction === 'up' ? index - 1 : index + 1
        if (newindex >= 0 && newindex < this.items.length) {
          newheading = this.$refs['itemheadingref' + +newindex][0]
        } else return
        var itemNewIndex = direction == 'down' ? 0 : newheading.items.length
        newheading.moveToGroup(
          item.source,
          newheading.column.item._id,
          itemNewIndex
        )
      }
    },
    orderGroup (direction, itemid, index) {
      var newindex = direction === 'up' ? index - 1 : index + 1
      if (this.showOthers === 'above') newindex = newindex - 1
      console.log('new index', newindex)
      if (newindex >= 0 && newindex < this.items.length) {
        this.hview.setOrder(itemid, newindex)
      }
    },
    async arrowOrder (direction) {
      var listItemDiv = document.querySelectorAll('.navigate')
      var selectedItem = localStorage.getItem('selected')
      console.log('selected item to move', selectedItem)
      var doc = document.getElementById('itemsource' + selectedItem)
      var index = Number(doc.getAttribute('data-index'))
      var column = doc.getAttribute('data-column')
      var itemtype = doc.getAttribute('data-type')
      // order heading
      if (itemtype === 'heading') {
        if (this.groupBy === 'heading' && column !== 'others') {
          this.orderGroup(direction, selectedItem, index)
        }
        return
      }
      // order item
      var headingref = this.$refs['itemheadingref' + index][0]
      if (typeof headingref !== 'undefined') {
        var itemref = headingref.$refs['itemref' + selectedItem][0]
        this.setItemOrderArrow(
          direction,
          selectedItem,
          itemref,
          listItemDiv,
          headingref
        )
      }
    },
    // set item order in the group using arrow key
    setItemOrderArrow (direction, itemid, itemref, listItemDiv, headingref) {
      var newindex =
        direction === 'up' ? itemref.itemindex - 1 : itemref.itemindex + 1
      console.log('headingref.items.length', headingref.items.length)
      console.log('index set', newindex)
      // check for last and first heading
      if (
        this.groupBy !== 'none' &&
        direction === 'up' &&
        headingref.headingIndex === 0 &&
        itemref.itemindex === 0
      ) {
        console.log('first heading first item do nothing')
      } else if (
        this.groupBy !== 'none' &&
        direction === 'down' &&
        headingref.headingIndex === this.items.length - 1 &&
        itemref.itemindex == headingref.items.length - 1
      ) {
        console.log('last heading last item do nothing')
      } else if (newindex === -1 || newindex === headingref.items.length) {
        this.arrowOrderGroup(direction)
      } else headingref.setOrder(itemid, newindex)
    },
    // add item after a selected item if not then add item to first(It is for Big + button)
    addNewInlineItem () {
      this.$nextTick(() => {
        if (this.isItemFocused()) return
        var sel = localStorage.getItem('selected')
        if (sel === null) {
          if (
            this.$refs &&
            this.$refs['itemheadingref0'] &&
            this.$refs['itemheadingref0'][0]
          ) {
            var column = this.$refs['itemheadingref0'][0].column
            this.$refs['itemheadingref0'][0].addNewInlineItem(column.item._id)
          }
        } else {
          var doc = document.getElementById('itemsource' + sel)
          if (doc) {
            var index = doc.getAttribute('data-index')
            var column = doc.getAttribute('data-column')
            if (
              this.$refs &&
              this.$refs['itemheadingref0'] &&
              this.$refs['itemheadingref' + index][0]
            ) {
              this.$refs['itemheadingref' + index][0].addNewInlineItem(column)
            }
          } else {
            // if no item found then remove localstorage and add new item at beginning
            localStorage.removeItem('selected')
            this.addNewInlineItem()
          }
        }
      })
    },
    deselectItem () {
      var sel = localStorage.getItem('selected')
      var doc = document.getElementById('itemsource' + sel)
      var index = doc.getAttribute('data-index')
      if (
        this.$refs &&
        this.$refs['itemheadingref' + index] &&
        this.$refs['itemheadingref' + index][0]
      ) {
        this.$refs['itemheadingref' + index][0].collapseSelected(sel)
      }
    },
    selectedArrowItem (itemDiv, newIndex, direction) {
      this.deselectItem()
      if (newIndex >= 0 && newIndex < itemDiv.length) {
        for (var i = 0; i < itemDiv.length; i++) {
          itemDiv[i].classList.remove('selected')
        }
        if (itemDiv[newIndex]) {
          itemDiv[newIndex].classList.add('selected')
          localStorage.setItem('selected', itemDiv[newIndex].id)
          this.scrollPage(itemDiv[newIndex])
        }
      } else if (newIndex === itemDiv.length) {
        this.currentItemIndex = itemDiv.length - 1
      } else {
        this.currentItemIndex = -1
      }
    },
    scrollPage (element) {
      if (typeof element !== 'undefined') {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'nearest'
        })
      }
    },
    // check for item is new or existing item opened
    isItemFocused () {
      var sel = localStorage.getItem('selected')
      var doc = document.getElementById('itemcollapse' + sel)
      if (doc != null) {
        var display = doc.style.display
        if (display === '') return true
      }
      var newitem = document.getElementById('newitem')
      if (newitem !== null) return true

      return false
    },
    nextItem (e) {
      if (e.altKey || e.metaKey || e.target.type === 'textarea') return
      var listItemDiv = document.querySelectorAll('.navigate')
      var sel = localStorage.getItem('selected')
      /*  if (sel !== null && this.isItemFocused()) {
        return
      } */
      if (this.selectedId !== '') {
        var arr = Array.prototype.slice.call(listItemDiv)
        this.currentItemIndex = arr.findIndex((e) => e.id === this.selectedId)
      }
      if (e.keyCode == 38 && this.currentItemIndex > 0) {
        this.focusItemByArrow(e, listItemDiv, 'up')
      } else if (
        e.keyCode == 40 &&
        this.currentItemIndex < listItemDiv.length
      ) {
        this.focusItemByArrow(e, listItemDiv, 'down')
      }
      this.selectedId = ''
    },
    focusItemByArrow (event, listItemDiv, direction) {
      event.preventDefault()
      this.currentItemIndex =
        direction === 'up'
          ? this.currentItemIndex - 1
          : this.currentItemIndex + 1
      this.selectedArrowItem(listItemDiv, this.currentItemIndex, direction)
    },
    async renderItems () {
      var taskData = []
      let group = this.groupBy
      if (group === 'assignee') {
        let headingItems = this.members
        taskData = this.getItems(headingItems, group)
      } else if (group === 'status') {
        let headingItems = [
          {
            name: 'backlog'
          },
          {
            name: 'inprogress'
          },
          {
            name: 'done'
          }
        ]
        taskData = this.getItems(headingItems, group)
      } else if (group === 'heading') {
        var headingItems = this.hview.items
        taskData = this.getItems(headingItems, group, this.hview)
      } else {
        taskData = this.getItems([], group)
      }
      return taskData
    },
    addNewHeading () {
      this.headingSection = true
      this.$nextTick(() => {
        this.$refs.headingTitle.focus()
      })
    },
    async saveHeading () {
      if (this.headingTitle !== '') {
        var headingItem = {
          _id: this.utils.uuidv4(),
          type: 'heading',
          container: this.perspective,
          title: this.headingTitle
        }
        await this.view.put(headingItem)
        if (this.selectedView.orderable) {
          await this.view.setOrder(headingItem._id, 0)
        }
      }
      this.headingSection = false
      this.headingTitle = ''
    },
    cancelHeading () {
      this.headingSection = false
      this.headingTitle = ''
    },
    async getItems (
      headingItems,
      groupKey,
      parentview = null,
      columnKey = 'name'
    ) {
      var self = this
      var taskData = []
      // create sub view for all heading items
      headingItems.forEach(async function (value, key) {
        let tempObj = null
        if (groupKey === 'heading') {
          tempObj = {
            item: value
          }
        } else {
          tempObj = {
            item: {
              _id: value[columnKey],
              title: value[columnKey],
              renderkey: groupKey + value[columnKey]
            }
          }
        }
        taskData.push(tempObj)
      })
      var showothers = this.showOthers
      if (showothers !== 'hide' || groupKey === 'none') {
        // this section use for filter items which does not contain any heading
        var tempObj = null
        tempObj = {
          item: {
            _id: 'others',
            title: 'others',
            renderkey: groupKey + 'others',
            _rev: Utility.uuidv4()
          }
        }
        // COMMENTED only shows others on above
        // if (showothers === 'above') {
        taskData.unshift(tempObj)
        // } else {
        //  taskData.push(tempObj)
        // }
      }
      if (groupKey === 'heading' && this.properties.display.fields.checkbox) {
        taskData.push({
          item: {
            _id: 'completed',
            title: 'completed',
            renderkey: groupKey + 'completed',
            _rev: Utility.uuidv4()
          }
        })
      }
      return taskData
    },

    // REVIEW never called?  - if uncommented rewrite to use repo.removeItem(...)
    // removeItem(item) {
    //   this.collection.remove(item);
    // },

    openDetail (item) {
      if (this.isInbox) {
        this.$router.push({
          name: 'list',
          params: {
            account: this.account,
            collectionID: this.collectionID,
            perspective: '_inbox',
            mode: item._id
          }
        })
      } else {
        this.$router.push({
          name: 'list',
          params: {
            account: this.account,
            collectionID: this.collectionID,
            perspective: item._id
          }
        })
      }
    },

    toggleCompletion (item) {
      const completed = !item.completed
      this.view.put({ ...item, ...{ completed } })
    },

    setCompletion (item, completed) {
      this.view.put({ ...item, ...{ completed } })
    },
    removeCompleted () {
      //   this.items = filters.active(this.items)
    },
    moved (evt, originalEvent) {
      if (
        this.showOthers === 'below' &&
        evt.draggedContext.futureIndex === this.items.length - 1
      ) {
        return false
      } else if (
        this.showOthers === 'above' &&
        evt.draggedContext.futureIndex === 0
      ) {
        return false
      }
      return true
    },
    async orderChangeHeading (event) {
      if (typeof event.moved !== 'undefined') {
        var element = event.moved.element
        var newIndex = event.moved.newIndex
        console.log('set order heading', element, newIndex)
        if (this.showOthers === 'above') {
          this.hview.setOrder(element.item._id, newIndex - 1)
        } else this.hview.setOrder(element.item._id, newIndex)
      }
    },
    dragStart (e) {
      this.disabledrop = true
    },
    dragEnd (e) {
      this.disabledrop = false
    }
  },

  directives: {
    'item-focus': function (el, binding) {
      if (binding.value) el.focus()
    }
    // 'sortable': function (el, binding) {
    //   var sortable = new Sortable(el, binding.value || {});
    // }
  },

  watch: {
    'properties.display.fields.checkbox': {
      handler: async function (newVal, oldVal) {
        if (newVal != null && oldVal !== newVal) {
          this.items = await this.renderItems()
        }
      }
    },
    showOthers: {
      handler: async function (newVal, oldVal) {
        if (newVal != null && oldVal !== newVal) {
          this.items = await this.renderItems()
        }
      },
      deep: true
    },
    groupBy: {
      handler: async function (newVal, oldVal) {
        if (newVal != null && oldVal !== newVal) {
          this.items = await this.renderItems()
        }
      },
      deep: true
    },
    hviewItems: {
      handler: async function (newVal, oldVal) {
        if (newVal != null) {
          this.items = await this.renderItems()
        }
      },
      deep: true
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.link {
  cursor: pointer;
}

.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.closedraweradd {
  position: fixed;
  z-index: 1;
  right: 20px;
  bottom: 15px;
}
.opendraweradd {
  position: fixed;
  z-index: 1;
  right: 20px;
  bottom: 15px;
}
</style>
