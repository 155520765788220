<template>
  <div>
    <div v-if="imageerror && showNoThumbnail"></div>
    <div
      class="text-center"
      v-else-if="imageerror"
      :class="[{ borderRadius, bottomRadius }]"
    >
      <v-avatar
        tile
        :style="
          'min-height:50px;width:110px;height:' + height + ';line-height:10'
        "
      >
        <span class="heading">No Thumbnail</span>
      </v-avatar>
    </div>
    <GalleryPlaceholder
      class="fimage"
      v-else-if="firstitem"
      :item="firstitem"
      :downloadable="downloadable"
      :height="height"
      :thumbnail="thumbnail"
      :original="original"
      :thumbSize="thumbSize"
      :borderRadius="borderRadius"
      :bottomRadius="bottomRadius"
      :parentKey="parentKey"
      :itemKey="itemKey"
      :areakey="areakey"
    ></GalleryPlaceholder>
  </div>
</template>
<script>
import Spaces from '@/components/services/spaces.js'
import Utility from '@/components/common/utils.js'
import GalleryPlaceholder from '@/components/GalleryPlaceholder.vue'
export default {
  name: 'FeatureImage',
  components: { GalleryPlaceholder },
  props: {
    areakey: String,
    itemKey: String,
    parentKey: String,
    item: Object,
    attachmentView: Object,
    collection: Object,
    thumbSize: {
      type: Object,
      default: function () {
        return { width: 0, height: 0 }
      }
    },
    borderRadius: {
      type: Boolean,
      default: function () {
        return false
      }
    },
    downloadable: {
      type: Boolean,
      default: function () {
        return false
      }
    },
    bottomRadius: {
      type: Boolean,
      default: function () {
        return false
      }
    },
    height: {
      type: String,
      default: function () {
        return 'auto'
      }
    },
    thumbnail: {
      type: Boolean,
      default: function () {
        return true
      }
    },
    original: {
      type: Boolean,
      default: function () {
        return false
      }
    },
    showNoThumbnail: {
      type: Boolean,
      default: function () {
        return true
      }
    },
  },
  data: function () {
    return {
      loading: true,
      imageerror: false,
      firstitem: null
    }
  },
  created () {
    this.getFeatureImage()
  },
  mounted () {},
  computed: {
    viewItems () {
      if (this.attachmentView && this.attachmentView.items.length > 0) {
        return this.attachmentView.items
      }
      return null
    }
  },
  methods: {
    getValidImage (featuredAttachment) {
      this.firstitem =
        this.attachmentView && this.attachmentView.itemMap[featuredAttachment]
      return this.firstitem
        ? Utility.isImage(this.firstitem.content_type)
        : false
    },
    firstValidImageExists () {
      this.firstitem = this.attachmentView && this.attachmentView.items[0]
      if (this.firstitem && this.getValidImage(this.firstitem._id)) {
        return true
      }
      return false
    },
    hasSpaceKey(){
      if(this.item.space_key) {
        this.firstitem=this.item 
      return true
      }
      return false
    },
    getFeatureImage () {
      var hasFeatureimage =
        (this.item.featuredAttachment &&
          this.getValidImage(this.item.featuredAttachment)) ||
        this.firstValidImageExists() || this.hasSpaceKey()
        this.imageerror=!hasFeatureimage
        if(this.imageerror)
         this.$emit('hasImage', { item: null, hasImage:false })
    }
  },
  watch: {
    viewItems: {
      handler: function (newVal, oldVal) {
        if (newVal) {
          this.getFeatureImage()
        }
      },
      deep: true
    },
    imageerror: function (newVal, oldVal) {
      this.$emit('imageerror', newVal)
    },
    firstitem (val) {
      this.$emit('hasImage', { item: val, hasImage: val && !this.imageerror })
    }
  }
}
</script>
<style scoped>

</style>
