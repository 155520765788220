<template>
  <v-menu bottom offset-y>
    <template v-slot:activator="{ on }">
      <v-btn
        :disabled="disabled"
        text
        small
        v-on="on"
        title="Add assignee"
        block
        tile
        class="left-btn d-inline-block"
      >
        <v-icon class="mr-2">mdi-account-plus</v-icon> Add Assignee
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        v-for="member in members"
        :key="member._rev"
        :title="member.name"
        @click="setAssignee(member.name)"
      >
        <v-list-item-avatar size="30" color="orange accent-1">
          <AsyncAvatarImage :username="member.name" :collection="collection" />
        </v-list-item-avatar>
        <v-list-item-title>
          {{ member.display || member.name}}
          <v-icon
            color="green"
            v-if="item.assignee && item.assignee == member.name"
            class="ml-2"
            small
            >mdi-check</v-icon
          >
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import AsyncAvatarImage from '@/components/AsyncAvatarImage.vue'
import Utility from '@/components/common/utils.js'

export default {
  name: 'AssigneeButton',
  props: ['item', 'collection', 'disabled', 'collectionMembersView'],
  data: function () {
    return {
      utils: Utility
    }
  },
  computed: {
    members () {
      return this.collectionMembersView !== null
        ? this.collectionMembersView.items.filter((x) => x.name)
        : []
    }
  },
  created () {},
  methods: {
    setAssignee (member) {
      this.$emit('setAssignee', member)
    }
  },
  components: { AsyncAvatarImage }
}
</script>
<style scoped>
@media (min-width: 320px) and (max-width: 480px) {
  .closedrawer > * {
    flex: 1 1 auto;
  }
  .opendrawer > * {
    flex: 1 1 auto;
  }
}
.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
  background-color: #b6b3b3 !important;
}
</style>
