<template>
  <section v-if="view !== null">
    <div v-if="groupBy === 'none'">
      <v-row
        no-gutters
        v-for="(column, key, index) in items"
        :key="'column' + column.item._id"
      >
        <v-col
          md="3"
          sm="12"
          v-for="item in viewItems"
          :key="'card' + item._id"
          class="px-2"
        >
          <BoardCard
            :perspective="perspective"
            :source="item"
            :collection="collection"
            v-on:openItem="openDetail"
            :authorization="authorization"
            class="mt-3"
            :allowDrag="false"
            :headingIds="items"
            :key="'boardcolumn' + column.item.renderkey"
            :column="column"
            :collectionID="collectionID"
          ></BoardCard>
        </v-col>
      </v-row>
    </div>
    <div class="container-kanban" v-else>
      <div class="kanban-board u-fancy-scrollbar">
        <div
          class="kanban-col"
          v-show="currentView !== null && currentView.showDescription"
          v-outside-click="setBody"
        >
          <div class="card-list bg-gray-100">
            <div class="card-list-header"></div>
            <div class="card-list-body">
              <v-card elevation="1" class="ma-1 pa-1" outlined>
                <text-area
                  :autoheight="true"
                  :authorization="authorization"
                  v-model="currentView.body"
                  @attachment="syncAttachment"
                  :collection="collection"
                />
              </v-card>
            </div>
          </div>
        </div>
        <draggable
          :animation="200"
          :delay="100"
          :delayOnTouchOnly="true"
          :touchStartThreshold="1"
          ghost-class="ghost"
          :disabled="
            !orderable || !authorization.update || groupBy !== 'heading'
          "
          :list="items"
          @change="orderChangeHeading($event)"
          handle=".headitem"
          group="heading"
          class="d-flex"
          @start="dragStart"
          @end="dragEnd"
          v-bind="dragOptions"
        >
          <div
            v-for="(column, key, index) in items"
            :key="'column' + column.item._id"
            class="kanban-col"
          >
            <BoardColumn
              :headingIds="items"
              :key="'boardcolumn' + column.item.renderkey"
              :column="column"
              :authorization="authorization"
              :collectionID="collectionID"
              :account="account"
              :collection="collection"
              :perspective="perspective"
              :properties="properties"
              :groupBy="groupBy"
              :orderable="orderable"
              :view="view"
              :disabledrop.sync="disabledrop"
              :editor="$refs.fileuploader"
            />
          </div>
        </draggable>
        <div class="kanban-col">
          <v-btn
            text
            @click="addNewHeading"
            v-show="groupBy === 'heading' && !headingSection"
          >
            <v-icon class="mr-1">mdi-plus</v-icon>Add new column
          </v-btn>
          <div v-show="headingSection">
            <div class="pt-0 column-width">
              <input
                ref="headingTitle"
                style="height: 20px; width: 305px"
                class="custom-input font-weight-bold h1 text-center mx-2"
                type="text"
                :readonly="!authorization.update"
                v-model="headingTitle"
                placeholder="Column title here..."
              />
              <v-btn
                small
                color="primary"
                class="my-2 ml-2"
                @click="saveHeading"
              >
                <v-icon class="mr-1">mdi-check</v-icon>Add
              </v-btn>
              <v-btn icon small fab class="my-2 ml-2" @click="cancelHeading">
                <v-icon class="mr-1">mdi-close</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </div>
    <FileUploader
      ref="fileuploader"
      :collection="collection"
      :multipleAttachment="true"
      :groupBy="groupBy"
      :view="view"
    />
  </section>
</template>

<script>
import { View } from '@/collections'
import lodash from 'lodash'
import FileUploader from '@/components/FileUploader.vue'
import _attachment from '@/components/common/attachment.js'
import Utility from '@/components/common/utils.js'

export default {
  name: 'BoardLayout',
  components: {
    FileUploader,
    BoardColumn: () => import('@/components/layouts/BoardColumn.vue'),
    BoardCard: () => import('@/components/layouts/BoardCard.vue'),
    TextArea: () => import('@/components/common/markdownit/text-area.vue')
  },
  props: {
    layout: {
      type: String,
      default: ''
    },
    collection: Object,
    perspective: String,
    selectedView: Object,
    msg: String,
    authorization: Object,
    collectionID: String,
    account: String,
    properties: {
      type: Object,
      default: function () {
        return {
          columnMap: [
            {
              columnName: 'Backlog',
              columnKey: 'backlog'
            },
            {
              columnName: 'In progress',
              columnKey: 'inprogress'
            },
            {
              columnName: 'Done',
              columnKey: 'done'
            }
          ]
        }
      }
    },
    collectionMembersView: Object
  },
  data () {
    return {
      disabledrop: false,
      hview: null,
      currentView: null,
      items: null,
      view: null,
      newItem: '',
      headingSection: false,
      headingTitle: '',
      attachmentInfo: []
    }
  },
  created () {
    this.currentView = Object.assign({}, this.selectedView)
  },
  async mounted () {
    this.hview = await new View(this.collection).open(this.perspective, {
      type: 'heading',
      container: this.perspective
    })
    this.view = await new View(this.collection).open(this.perspective, {
      type: { $ne: 'heading' }
    })
  },
  computed: {
    dragOptions () {
      return {
        scroll: true,
        scrollSensitivity: 100,
        scrollSpeed: 10
      }
    },
    viewItems () {
      console.log('trigger view items')
      return this.view !== null ? this.view.items : null
    },
    hviewItems () {
      console.log('trigger header view items')
      return this.hview !== null ? this.hview.items : null
    },
    members () {
      return this.collectionMembersView !== null
        ? this.collectionMembersView.items.filter((x) => x.name)
        : []
    },
    /**
     *read groupBy properties from view and enable/disable grouping
     */
    groupBy () {
      if (
        typeof this.selectedView !== 'undefined' &&
        (this.selectedView.groupBy ||
          typeof this.selectedView.groupBy === 'string')
      ) {
        return this.selectedView.groupBy
      }
      return 'none'
    },
    showOthers () {
      if (
        typeof this.selectedView !== 'undefined' &&
        (this.selectedView.show_others ||
          typeof this.selectedView.show_others === 'string')
      ) {
        return this.selectedView.show_others
      }
      return 'below'
    },
    /**
     *read orderable properties from view and enable/disable itemList dragging
     */
    orderable () {
      if (
        typeof this.selectedView !== 'undefined' &&
        (this.selectedView.orderable ||
          typeof this.selectedView.orderable === 'string')
      ) {
        return true
      }
      return false
    },
    changeSave () {
      if (!lodash.isEqual(this.selectedView, this.currentView) && this.authorization.update) {
        return true
      }
      return false
    }
  },
  methods: {
    async renderItems () {
      var taskData = []
      let group = this.groupBy
      if (group === 'assignee') {
        let headingItems = this.members
        taskData = this.getItems(headingItems, group)
      } else if (group === 'status') {
        let headingItems = [
          {
            name: 'backlog'
          },
          {
            name: 'inprogress'
          },
          {
            name: 'done'
          }
        ]
        taskData = this.getItems(headingItems, group)
      } else if (group === 'heading') {
        var headingItems = this.hview.items
        taskData = this.getItems(headingItems, group, this.hview)
      } else {
        taskData = this.getItems([], group)
      }
      return taskData
    },
    addNewHeading () {
      this.headingSection = true
      this.$nextTick(() => {
        this.$refs.headingTitle.focus()
      })
    },
    async saveHeading () {
      var headingItem = {
        type: 'heading',
        container: this.perspective,
        title: this.headingTitle
      }
      await this.view.put(headingItem)
      this.headingSection = false
      this.headingTitle = ''
    },
    cancelHeading () {
      this.headingSection = false
      this.headingTitle = ''
    },
    async getItems (
      headingItems,
      groupKey,
      parentview = null,
      columnKey = 'name'
    ) {
      var self = this
      var taskData = []
      // create sub view for all heading items
      headingItems.forEach(async function (value, key) {
        let tempObj = null
        if (groupKey === 'heading') {
          tempObj = {
            item: value
          }
        } else {
          tempObj = {
            item: {
              _id: value[columnKey],
              title: value[columnKey],
              renderkey: groupKey + value[columnKey]
            }
          }
        }
        taskData.push(tempObj)
      })
      var showothers = this.showOthers
      if (showothers !== 'hide' || groupKey === 'none') {
        // this section use for filter items which does not contain any heading
        var tempObj = null
        tempObj = {
          item: {
            _id: 'others',
            title: 'others',
            renderkey: groupKey + 'others',
            _rev: Utility.uuidv4()
          }
        }
        // COMMENTED only shows others on above
        // if (showothers === 'above') {
        taskData.unshift(tempObj)
        // } else {
        //  taskData.push(tempObj)
        // }
      }
      return taskData
    },

    // REVIEW never referenced? - if uncommented then needs to use repo.removeItem()
    // async removeItem(item) {
    // ...
    // },

    openDetail (item) {
      this.$router.push({
        name: 'list',
        params: {
          account: this.account,
          collectionID: this.collectionID,
          perspective: item._id
        }
      })
    },
    dragStart (e) {
      this.disabledrop = true
    },
    dragEnd (e) {
      this.disabledrop = false
    },
    orderChangeHeading (event) {
      if (typeof event.moved !== 'undefined') {
        var element = event.moved.element
        var newIndex = event.moved.newIndex
        console.log('set order heading', element, newIndex)
        if (this.showothers === 'above') { this.hview.setOrder(element.item._id, newIndex - 1) } else this.hview.setOrder(element.item._id, newIndex)
      }
    },

    async setBody () {
      if (!this.changeSave) return
      var view = await new View(this.collection).open('views')
      var result = await view.put(this.currentView)
      if (this.attachmentInfo.length > 0) {
        await _attachment.add(this.currentView, this.attachmentInfo, this.collection)
        this.attachmentInfo = []
      }
      this.$emit('update:selectedView', this.currentView)
    },
    syncAttachment (value) {
      for (var i = 0; i < value.length; i++) {
        this.attachmentInfo.push(value[i])
      }
    }
  },
  watch: {
    showOthers: {
      handler: async function (newVal, oldVal) {
        if (newVal != null && oldVal !== newVal) {
          this.items = await this.renderItems()
        }
      },
      deep: true
    },
    groupBy: {
      handler: async function (newVal, oldVal) {
        if (newVal != null && oldVal !== newVal) {
          this.items = await this.renderItems()
        }
      },
      deep: true
    },
    hviewItems: {
      handler: async function (newVal, oldVal) {
        if (newVal != null) {
          this.items = await this.renderItems()
        }
      },
      deep: true
    }
  }
}
</script>

<style scoped>
.column-width {
  min-width: 320px;
  width: 320px;
}
.theme--light .bg-gray-100 {
  background-color: #f5f5f5;
}
.theme--dark .bg-gray-100 {
  background-color: #525252;
}
.headitem {
  cursor: move;
}
.displaynone {
  display: none !important;
}
.container-kanban {
  height: calc(100vh - 53px);
  display: flex;
  flex-direction: column;
}
.kanban-board {
  flex: 1;
  white-space: nowrap;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
}
.kanban-col {
  max-width: 19.5rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  flex: 1 0 auto;
  width: calc(100vw - 24px);
  max-height: 100%;
  margin-right: 0.75rem;
  padding-bottom: 0.75rem;
  outline: none !important;
}
@media only screen and (max-width: 959px){
.container-kanban {
  height: calc(100vh - 100px);
}
}
</style>
