<template>
  <!-- membership modal -->
  <v-dialog v-model="membershipDialog" persistent max-width="900">
    <v-card>
      <v-card-title
        class="dialog-heading subtitle-1 font-weight-bold justify-center"
        >Share "{{ collection.info.title }}"</v-card-title
      >
      <v-row no-gutters class="px-2">
        <v-col cols="12" class="text-center my-2">
          <h4>Use this form to invite users to your channel</h4>
          <h5>
            You can invite a user by their Jot username, their phone number, or
            their email
          </h5>
        </v-col>
      </v-row>
      <v-radio-group v-model="via" row @change="changeVia">
        <v-row no-gutters class="px-2">
          <v-col cols="4" class="pa-1">
            <v-radio value="user">
              <template v-slot:label>
                <v-combobox
                  ref="username"
                  v-model="membership.username"
                  :items="users"
                  item-text="name"
                  item-value="name"
                  hide-no-data
                  hide-selected
                  :return-object="false"
                  :search-input.sync="search"
                  label="Username"
                  @input="setMembershipUsername"
                  @input.native="setMembershipUsername"
                  @change="checkExistingUser"
                  :disabled="!viaUser"
                  :error-messages="userErrors['username']"
                />
              </template>
            </v-radio>
          </v-col>
          <v-col cols="4" class="pa-1">
            <v-radio value="phone">
              <template v-slot:label>
                <v-text-field
                  ref="phone"
                  v-model="membership.phone"
                  label="Phone number"
                  :disabled="!viaPhone"
                  :error-messages="userErrors['phone']"
                />
              </template>
            </v-radio>
          </v-col>
          <v-col cols="4" class="pa-1">
            <v-radio value="email">
              <template v-slot:label>
                <v-text-field
                  ref="email"
                  v-model="membership.email"
                  label="E-mail"
                  :disabled="!viaEmail"
                  :error-messages="userErrors['email']"
                />
              </template>
            </v-radio>
          </v-col>
        </v-row>
      </v-radio-group>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="cancel()">Cancel</v-btn>
        <v-btn
          color="primary"
          @click="saveMembership()"
          :disabled="checkForInvitation()"
          >Invite</v-btn
        >
      </v-card-actions>
      <v-divider />
      <div class="members-area">
        <v-row no-gutters class="px-2">
          <v-col cols="12" class="text-center my-2">
            <h4>See current members and change user roles</h4>
          </v-col>
          <v-col cols="12">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Contact</th>
                    <th class="text-left">Status</th>
                    <th class="text-left">Role</th>
                    <th class="text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="memberItems.length==0"  class="text-center"><td colspan="5">No Members Found</td></tr>
                  <tr v-for="item in memberItems" :key="item._id">
                    <td style="width: 200px">
                      <span v-if="item.invitation">
                        {{item.name || item.invitation.name || item.invitation.phone || item.invitation.email}}
                      </span>
                      <span v-else-if="item.status==='member'">
                        {{item.name}}
                      </span>
                      <span v-else>
                        {{item.name || item.phone || item.email}}
                      </span>
                    <td style="width: 250px">{{ item.status }}</td>
                    <td style="width: 250px">
                      <v-select
                        :items="roleList"
                        v-model="item.roles"
                        multiple
                        dense
                        flat
                        @input="updateRole(item)"
                      />
                    </td>
                    <td class="text-center">
                      <v-menu bottom offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            fab
                            x-small
                            v-bind="attrs"
                            v-on="on"
                            title="Action"
                            icon
                          >
                            <v-icon>mdi-dots-horizontal</v-icon>
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item v-for="btn in btnStatus(item)" :key="btn" @click="updateMember(item,btn)">
                            <v-list-item-title>
                              <v-icon small color="primary">mdi-pencil</v-icon>
                              {{ btn }}
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </div>
      <v-divider />
      <v-row no-gutters class="px-2" v-if="invitedMembers.length>0">
        <v-col cols="12" class="text-right my-2">
          <v-btn color="primary" @click="hideInvited = !hideInvited">{{
            `${
              hideInvited ? `Show ${invitedMembers.length} More` : " Hide"
            } Invited`
          }}</v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import { View } from '@/collections'
import {
  parsePhoneNumberFromString,
  parsePhoneNumberWithError,
  parsePhoneNumber
} from 'libphonenumber-js/min/'
import UserInfo from '@/components/UserInfo.vue'
import moment from 'moment'
export default {
  name: 'MembershipShareDialog',
  data: function () {
    return {
      via: null,
      viaUser: false,
      viaPhone: false,
      viaEmail: false,
      statusList: [
        'invited',
        'cancelled',
        'banned',
        'member',
        'noreply',
        'error'
      ],
      roleList: ['moderator', 'admin', 'trusted'],
      users: [],
      search: null,
      membershipDialog: false,
      membership: { email: '', phone: '', username: '' },
      username: '',
      email: '',
      phone: '',
      isExists: false,
      hideInvited: true,
      userErrors: { phone: '', email: '', username: '' }
    }
  },
  props: {
    collection: Object,
    usersView: Object,
    repo: Object,
    collectionMembersView: Object
  },
  components: { UserInfo },
  computed: {
    invitedMembers () {
      return this.collectionMembersView !== null
        ? this.collectionMembersView.items.filter((x) => x.status === 'invited')
        : []
    },
    memberItems () {
      if (this.hideInvited) {
        return this.collectionMembersView !== null
          ? this.collectionMembersView.items.filter(
            (x) => x.status != 'invited'
          )
          : []
      }
      return this.collectionMembersView !== null
        ? this.collectionMembersView.items
        : []
    }
  },
  mounted () {},
  methods: {
    changeVia () {
      var v = this.via
      if (v === 'user') {
        this.viaUser = true
        this.viaPhone = this.viaEmail = false
        this.membership.email = this.membership.phone = ''
        setTimeout(() => {
          this.$refs.username.focus()
        }, 100)
      } else if (v === 'phone') {
        this.viaPhone = true
        this.viaUser = this.viaEmail = false
        this.membership.username = this.membership.email = ''
        setTimeout(() => {
          this.$refs.phone.focus()
        }, 100)
      } else if (v === 'email') {
        this.viaEmail = true
        this.viaUser = this.viaPhone = false
        this.membership.username = this.membership.phone = ''
        setTimeout(() => {
          this.$refs.email.focus()
        }, 100)
      }
      this.userErrors = { phone: '', email: '', username: '' }
    },
    setMembershipUsername (e) {
      if (!e) {
        this.membership.username = ''
      } else if (typeof e === 'string') {
        this.membership.username = e
      } else if (typeof e === 'object') {
        this.membership.username = e.srcElement.value
      }
    },
    async getUser (item) {
      return await this.utils.getUserDetails(this.collection, item.name)
    },
    checkForInvitation () {
      if (
        this.membership.username !== '' ||
        this.membership.email !== '' ||
        this.membership.phone !== ''
      ) {
        return false
      }
      return true
    },
    checkExistingUser () {
      if (this.checkUsernameExists(this.membership.username)) {
        this.isExists = true
      } else {
        this.isExists = false
      }
    },
    checkUsernameExists (user) {
      return this.usersView.items.find((x) => x.name == user)
    },
    querySelections (v) {
      this.users = this.usersView.items.filter((e) => {
        return (
          (e.name || '').toLowerCase().indexOf((v || '').toLowerCase()) > -1 &&
          e.name !== this.repo.userID
        )
      })
    },
    openMembershipDialog () {
      this.membership.currentUrl = window.location.href
      this.membershipDialog = true
    },
    saveMembership () {
      let valid = true
      this.userErrors = { phone: '', email: '', username: '' }
      if (this.membership.phone) {
        const n = parsePhoneNumberFromString(this.membership.phone, 'US')
        if (n && !n.isValid()) {
          this.userErrors.phone = 'Invalid Phone number'
          valid = false
        } else if (typeof n === 'undefined') {
          this.userErrors.phone = 'Invalid Phone number'
          valid = false
        }
      }
      if (
        this.membership.email !== '' &&
        !isValidEmailAddress(this.membership.email)
      ) {
        this.userErrors.email = 'Invalid Email'
        valid = false
      }

      if (
        this.membership.username !== '' &&
        !isValidUserName(this.membership.username)
      ) {
        this.userErrors.username = 'Invalid Username'
        valid = false
      }
      if (valid) {
        this.confirmSubmit()
      }
    },
    confirmSubmit () {
      let selectedcollection = Object.assign({}, this.collection.info)
      let membershipObject = null
      this.membership.invitation = {}
      if (this.isExists) {
        membershipObject = {
          _id:
            '.membership.' +
            this.membership.username +
            '.' +
            selectedcollection['@dbid'],
          '@dbid': selectedcollection['@dbid'],
          account: selectedcollection.account,
          name: this.membership.username,
          type: 'membership',
          title: selectedcollection.title,
          status: 'member',
          roles: ['trusted']
        }
      } else {
        membershipObject = {
          '@dbid': selectedcollection['@dbid'],
          account: selectedcollection.account,
          type: 'membership',
          title: selectedcollection.title,
          status: 'invited',
          roles: ['trusted']
        }
      }
      membershipObject.invitation = {}
      membershipObject.invitation = this.getInvitation()
      console.debug('Membership sent', membershipObject)
      this.collectionMembersView.put(membershipObject)
      this.membership = {
        username: '',
        email: '',
        phone: ''
      }
      this.isExists = false
      this.via = null
    },
    getInvitation () {
      let invitation = {}
      invitation.from = this.repo.userID
      if (this.membership.email !== '') { invitation.email = this.membership.email }
      if (this.membership.phone !== '') {
        // convert phonenumber
        const n = parsePhoneNumberFromString(this.membership.phone, 'US')
        invitation.phone = n.number
      }
      if (this.membership.username !== '') { invitation.name = this.membership.username }
      return invitation
    },
    updateRole (item) {
      this.collectionMembersView.put(item)
    },
    updateMember (item,action) {
      if(action && action=="Cancel"){
        item.status='cancelled'

      }else if (item.status === 'member') {
        item.status = 'banned'
      } else if (item.status === 'cancelled' || item.status === 'banned' || item.status === 'error') {
        item.status = 'invited'
      } else if (
        item.status === 'invited' ||
        item.status === 'noreply' 
      ) {
        item.status = 'cancelled'
      }
      this.collectionMembersView.put(item)
    },
    cancel () {
      Object.assign(this.$data, this.$options.data.apply(this))
      this.membership = {
        username: '',
        email: '',
        phone: ''
      }
      this.membershipDialog = false
      this.isExists = false
    },
    getRole (roles) {
      return typeof roles !== 'undefined' ? roles.join() : ''
    },
    btnStatus (item) {
      if (item.status === 'member') {
        return ['Ban','Cancel']
      } else if (item.status === 'cancelled' || item.status === 'banned' || item.status==='error') {
        return ['Re-invite', 'Cancel']
      } else if (
        item.status === 'invited' ||
        item.status === 'noreply'
      ) {
        return ['Cancel']
      }
    }
  },
  watch: {
    search (val) {
      val && val !== this.membership.name && this.querySelections(val)
    }
  }
}
function isValidEmailAddress (s) {
  return matchesRe(
    s,
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  )
  // version that accepts unicode -- const re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
}

function isValidUserName (s) {
  // /^[a-zA-Z0-9]+$/ -- doesn't enforce starting
  return matchesRe(s, /^[a-z][a-z0-9]+$/)
}

function matchesRe (s, re) {
  return re.test(String(s).toLowerCase())
}
</script>
<style scoped>
.vue-lb-container {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  box-sizing: border-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  padding: 10px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2000;
  -webkit-align-items: center;
  -moz-box-sizing: border-box;
  -webkit-justify-content: center;
  -ms-flex-align: center;
  -webkit-box-align: center;
  -ms-flex-pack: center;
  -webkit-box-pack: center;
}
.parentcontainer {
  width: 90% !important;
}
.v-application--is-ltr .v-list-item__action:first-child,
.v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 10px !important;
}
</style>
