<template>
  <v-checkbox
    v-model="defaultPage"
    class="my-2 menu-btn"
    label="Set as Area's Default Page"
    @change="SaveDefaultPage"
    hide-details
  ></v-checkbox>
</template>
<script>
import { View, Collection } from '@/collections'
export default {
  name: 'CollectionDefaultPage',
  data: () => ({
    defaultPage: false,
    collectionDoc: null
  }),
  props: ['collection', 'repo', 'item'],
  components: {},
  async created () {
    this.collectionDoc = await this.repo.get(
      Collection.docIDFor(this.collection.collectionID)
    )
    this.defaultPage =
      !!(this.collectionDoc.defaultPage &&
      this.item._id == this.collectionDoc.defaultPage)
  },
  destroyed () {},
  mounted () {},
  methods: {
    async SaveDefaultPage () {
      if (this.defaultPage) {
        var params = {
          defaultPage: this.item._id
        }
        Object.assign(this.collectionDoc, params)
      } else {
        delete this.collectionDoc.defaultPage
      }
      await this.repo.put(this.collectionDoc)
    }
  },
  computed: {},
  watch: {}
}
</script>
<style scoped>
</style>
