<template>
  <div class="ma-2" v-if="editItem !== null">
    <!-- Title area -->
    <div v-if="properties.details.fields.title">
      <input
        ref="headingTitle"
        type="text"
        :readonly="!authorization.update"
        v-model="editItem.title"
        placeholder="Title here..."
        class="custom-heading text-center"
        @blur="save"
        @keydown.enter="save"
      />
    </div>
    <br />
    <!-- Description area -->
    <div v-if="properties.details.fields.description">
      <text-area
        :autoheight="false"
        ref="itemBody"
        :authorization="authorization"
        v-model="editItem.body"
        @attachment="syncAttachment"
        :collection="collection"
        v-outside-click="setBody"
        class="carousel"
      />
    </div>
  </div>
</template>
<script>
import TextArea from '@/components/common/markdownit/text-area.vue'
import _attachment from '@/components/common/attachment.js'
export default {
  name: 'GalleryDetailsEditor',
  components: { TextArea },
  props: {
    view: Object,
    item: Object,
    properties: Object,
    authorization: Object,
    collection: Object

  },
  data () {
    return {
      editItem: null,
      olditem: null,
      attachmentInfo: []
    }
  },
  mounted () {
    this.olditem = Object.assign({}, this.item)
    this.editItem = Object.assign({}, this.item)
  },
  created () {},
  computed: {},
  methods: {
    save () {
      if (this.olditem.title !== this.editItem.title) {
        console.log('save title')
        this.view.put(this.editItem)
        this.olditem.title = this.editItem.title
      }
    },
    async setBody () {
      if (this.olditem.body !== this.editItem.body) {
        console.log('save title')
        this.view.put(this.editItem)
        if (this.attachmentInfo.length > 0) {
          await _attachment.add(this.editItem, this.attachmentInfo, this.collection)
          this.attachmentInfo = []
        }
        this.olditem.body = this.editItem.body
      }
    },
    syncAttachment (value) {
      for (var i = 0; i < value.length; i++) {
        this.attachmentInfo.push(value[i])
      }
    }
  }
}
</script>

<style scoped>
.hoverbuttonTitle {
  display: none;
}
.hoveritemTitle:hover .hoverbuttonTitle {
  display: inline-block;
}
.hoveritemDesc {
  width: 100%;
  position: relative;
}
.hoverbuttonDesc {
  display: none;
  position: absolute;
  bottom: 0;
  right: 0;
}
.hoveritemDesc:hover .hoverbuttonDesc {
  display: inline-block;
}
</style>
