<template>
  <div class="mt-2">
    <v-row no-gutters>
      <v-col
        v-for="(item, key, index) in attachments.itemMap"
        :key="key"
        lg="1"
        md="1"
        sm="1"
        :style="icon ? 'min-width:200px' : 'min-width:130px'"
      >
        <GalleryItem
          :parentView="parentView"
          :parentItem="parentItem"
          :collection="collection"
          :item="item"
          :view="attachments"
          :key="'galleryItem_' + key"
          :index="index"
          :height="height"
          :icon="icon"
        ></GalleryItem>
      </v-col>
    </v-row>
    <v-dialog v-model="galleryDialog" max-width="500">
      <v-row no-gutters class="carousel">
        <v-carousel
          :dark="$vuetify.theme.dark"
          :light="!$vuetify.theme.dark"
          hide-delimiters
          v-model="carouselIndex"
          hide-delimiter-background
          height="auto"
        >
          <v-carousel-item
            position="center"
            v-for="(item, key) in attachments.itemMap"
            :key="'attachmentCarousel_' + key"
          >
            <v-card style="min-height:350px">
              <GalleryPlaceholder
                :item="item"
                height="auto"
                thumbnail
                :thumbSize="{ width: 500, height: 350}"
                :key="'attachmentPlaceholder_' + item._id"
                :itemKey="'attachmentPlaceholderItem_' + item._rev"
                controls
              ></GalleryPlaceholder>
            </v-card>
          </v-carousel-item>
        </v-carousel>
      </v-row>
      <v-card
        tile
        no-gutters
        v-for="(item, key, index) in attachments.itemMap"
        :key="'attachmentComment_' + key"
      >
        <div v-if="carouselIndex === index">
          <v-divider></v-divider>
          <InlineComment
            :authorization="authorization"
            :collection="attachments.collection"
            ref="comment"
            :item="item"
            :key="'attachment_comment' + item._rev"
            v-if="item._id"
            class="px-2 pt-2"
          />
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Spaces from '@/components/services/spaces.js'
import InlineComment from '@/components/InlineComment.vue'
import GalleryItem from '@/components/GalleryItem.vue'
import GalleryPlaceholder from '@/components/GalleryPlaceholder.vue'
export default {
  name: 'GalleryAttachment',
  props: {
    attachments: Object,
    authorization: Object,
    collection: Object,
    parentItem: Object,
    parentView: Object,
    height: {
      type: String,
      default: '150px'
    },
    icon: {
      type: Boolean,
      default: true
    }
  },
  data: function () {
    return {
      galleryDialog: false,
      carouselIndex: 0,
      spaces: Spaces
    }
  },
  mounted () {
    let self = this
    // fire open carousel event from child to parent method
    self.$on('openCarousel', function (index) {
      self.openCarousel(index)
    })
  },
  methods: {
    openCarousel (index) {
      this.galleryDialog = true
      this.carouselIndex = index
    }
  },
  components: {
    InlineComment,
    GalleryItem,
    GalleryPlaceholder
  }
}
</script>

<style scoped>
.carousel {
  background-color: #fff;
}
.default {
  height: 50px;
}
.v-responsive .v-image {
  background-color: #fff;
}
.v-dialog {
  overflow-y: hidden;
}
</style>
