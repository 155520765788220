<template>
  <v-menu
    bottom
    offset-y
    :close-on-click="true"
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        :disabled="disabled"
        text
        small
        v-on="on"
        block
        tile
        title="Add tags"
        class="left-btn d-inline-block"
      >
        <v-icon class="mr-2">mdi-tag-plus</v-icon>Add tags
      </v-btn>
    </template>
    <v-list class="pa-0 ma-0">
      <v-list-item class="pa-0 ma-0">
        <div v-if="tags && tags.length == 0 && !authorization.update"></div>
        <div v-else style="width: 250px">
          <v-combobox
            :hide-details="true"
            full-width
            v-model="tags"
            :filter="filter"
            :hide-no-data="!search"
            :items="items"
            :search-input.sync="search"
            hide-selected
            label="Search for an option"
            multiple
            small-chips
            solo
            @change="setTags()"
          >
            <template v-slot:no-data>
              <v-list-item>
                <span class="subheading mr-2">Create</span>
                <v-chip color="grey lighten-1" label small>{{ search }}</v-chip>
              </v-list-item>
            </template>
            <template v-slot:selection="{ attrs, item, parent, selected }">
              <v-chip
                v-bind="attrs"
                color="grey lighten-1"
                :input-value="selected"
                label
                small
              >
                <span class="pr-2">{{ item }}</span>
                <v-icon small @click="parent.selectItem(item)"
                  >mdi-close</v-icon
                >
              </v-chip>
            </template>
            <template v-slot:item="{ index, item }">
              <v-text-field
                v-if="editing === item"
                v-model="editingtext"
                autofocus
                flat
                background-color="transparent"
                hide-details
                solo
                @keyup.enter="edit(index, item, editingtext)"
              ></v-text-field>
              <v-chip v-else color="grey lighten-1" dark label small>{{
                item
              }}</v-chip>
              <v-spacer></v-spacer>
              <v-list-item-action @click.stop>
                <v-btn
                  icon
                  @click.stop.prevent="edit(index, item, editingtext)"
                >
                  <v-icon>{{
                    editing !== item ? "mdi-pencil" : "mdi-check"
                  }}</v-icon>
                </v-btn>
              </v-list-item-action>
            </template>
          </v-combobox>
        </div>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>
export default {
  name: 'TagAgent',
  data: () => ({
    activator: null,
    attach: null,
    editing: null,
    editingtext: null,
    index: -1,
    items: ['Food', 'Travel'],
    menu: false,
    tags: [],
    x: 0,
    search: null,
    y: 0
  }),
  props: {
    authorization: Object,
    assignTags: Array,
    disabled: Boolean
  },
  watch: {
    tags (val, prev) {
      if (typeof val === 'undefined' || typeof prev === 'undefined') return
      if (val.length === prev.length) return
      this.tags = val.map((v) => {
        if (typeof v === 'string') {
          this.items.push(v)
        }
        return v
      })
    }
  },
  created () {
    this.tags = this.assignTags
  },
  methods: {
    /* edit tags */
    edit (index, item, editingtext) {
      if (!this.editing) {
        this.editing = item
        this.editingtext = item
        this.index = index
      } else {
        this.editing = null
        this.editingtext = null
        this.index = -1
      }
    },
    /* Filter tags */
    filter (item, queryText, itemText) {
      return (
        itemText
          .toString()
          .toLowerCase()
          .indexOf(queryText.toString().toLowerCase()) > -1
      )
    },
    /* return tag values to parent component */
    setTags () {
      this.$emit('setTags', this.tags)
    }
  }
}
</script>
<style scoped>
.v-chip.v-size--small {
  border-radius: 12px !important;
}
@media (min-width: 320px) and (max-width: 480px) {
  .closedrawer > * {
    flex: 1 1 auto;
  }
  .opendrawer > * {
    flex: 1 1 auto;
  }
}
.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
  background-color: #b6b3b3 !important;
}
</style>
