<!-- Component to edit an item -->

<template>
  <section>
    <div v-for="layout in item.layouts" :key="layout.type">
      <v-radio-group v-model="item.default" hide-details="false">
        <v-row no-gutters>
          <v-col cols="12" class="background">
            <span>{{ utils.ucfirst(layout.type)}}</span>
            <v-btn
              v-show="authorization.update"
              icon
              fab
              color="red"
              x-small
              class="float-right"
              @click="removeViewLayout(layout.type)"
            >
              <v-icon>mdi-close-circle</v-icon>
            </v-btn>
            <v-radio
              v-show="authorization.update"
              class="float-right mr-1"
              label="Default"
              :value="layout.type"
            ></v-radio>
            <div v-if="typeof form[layout.type]!=='undefined'">
              <v-chip
                v-if="!form[layout.type].valid"
                color="red"
                class="ml-2"
                text-color="white"
              >Invalid</v-chip>
              <v-divider class="mt-2" />
              <v-form v-model="form[layout.type].valid" :readonly="!authorization.update">
                <v-jsonschema-form
                  v-if="form[layout.type] && form[layout.type].schema"
                  :schema="form[layout.type].schema"
                  :model="form[layout.type].data"
                  @change="changeFormInput($event,layout.type)"
                  @input="formInput"
                />
              </v-form>
            </div>
          </v-col>
        </v-row>
      </v-radio-group>
    </div>
  </section>
</template>

<script>
import Utils from '@/components/common/utils.js'
import templates from '@/components/json/templates.json'
import formJson from '@/components/json/form.json'
import VJsonschemaForm from '@koumoul/vuetify-jsonschema-form'
import '@koumoul/vuetify-jsonschema-form/dist/main.css'
import lodash from 'lodash'
import flat from 'flat'

export default {
  name: 'LayoutEditor',
  data: function () {
    return {
      utils: Utils,
      templates: templates,
      form: formJson,
      options: {
        debug: false,
        disableAll: false,
        autoFoldObjects: true
      }
    }
  },
  props: {
    authorization: Object,
    item: Object
  },
  components: { VJsonschemaForm },
  created () {
    this.setFormData()
  },
  methods: {
    async removeViewLayout (layoutType) {
      this.item.layouts.pop(layoutType)
      if (!this.setItemData(this.form)) return false
    },
    changeFormInput (e, type) {
      if (!this.setItemDataBYtype(this.form, type)) return false
    },
    formInput (e) {},
    setFormData () {
      // set form data from original values
      for (var itemIndex in this.item.layouts) {
        if (
          this.item.layouts[itemIndex].hasOwnProperty('properties') &&
          typeof this.form[this.item.layouts[itemIndex].type] !== 'undefined'
        ) {
          this.form[this.item.layouts[itemIndex].type].data = flat.flatten(
            this.item.layouts[itemIndex].properties,
            {
              safe: true
            }
          )
        }
      }
    },
    /* set layout properties from from data */
    setItemDataBYtype (formData, layoutType) {
      var self = this
      var itemIndex = this.item.layouts.findIndex(function (item) {
        return item.type === layoutType
      })
      var type = this.item.layouts[itemIndex].type
      if (!this.item.layouts[itemIndex].hasOwnProperty('properties')) {
        this.item.layouts[itemIndex].properties = {}
      }
      if (!formData[type].valid) {
        return false
      }
      for (var formIndex in formData[type].data) {
        lodash.set(
          this.item.layouts[itemIndex].properties,
          formIndex,
          formData[type].data[formIndex]
        )
      }
      return true
    },
    /* set layout properties from from data */
    setItemData (formData) {
      for (var itemIndex in this.item.layouts) {
        var type = this.item.layouts[itemIndex].type
        if (!this.item.layouts[itemIndex].hasOwnProperty('properties')) {
          this.item.layouts[itemIndex].properties = {}
        }
        if (!formData[type].valid) {
          return false
        }
        for (var formIndex in formData[type].data) {
          lodash.set(
            this.item.layouts[itemIndex].properties,
            formIndex,
            formData[type].data[formIndex]
          )
        }
      }
      return true
    }
  },
  watch: {
    // Watch on change in url first param type means collection title,
    // if it changed then close old collection and open new collection
    item: {
      handler: function (newVal, oldVal) {
        if (newVal != null) {
          this.$emit('update:item', newVal)
        }
      },
      deep: true
    }
  },
  computed: {}
}
</script>

<style scoped>
.v-dialog > .v-card > .v-toolbar {
  position: sticky;
  top: 0;
  z-index: 999;
}
.v-subheader {
  height: auto;
  padding: 15px 15px;
}
.theme--light .background {
  padding: 5px;
  border-radius: 5px;
  background: rgb(204, 204, 204, 0.2) !important;
}
.theme--dark .background {
  padding: 5px;
  border-radius: 5px;
  background: rgb(10, 10, 10, 0.2) !important;
}
</style>
