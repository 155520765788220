<template>
  <span> {{ userInfo[datakey] }} </span>
</template>
<script>
import Utils from '@/components/common/utils.js'
export default {
  name: 'UserInfo',
  components: {},
  props: {
    collection: Object,
    username: String,
    datakey: {
      type: String,
      default: 'display'
    }
  },
  data: function () {
    return {
      utils: Utils,
      userInfo: Object
    }
  },
  async created () {
    this.userInfo = await this.utils.getUserDetails(
      this.collection,
      this.username
    )
  },
  mounted () {},
  computed: {},
  methods: {},
  watch: {}
}
</script>
