<template>
  <v-hover v-slot:default="{ hover }">
    <v-card
      :elevation="hover ? 5 : 1"
      :class="{ 'on-hover': hover }"
      class="ma-2"
    >
      <div
        @click="openCarousel(index)"
        :class="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-1'"
      >
        <GalleryPlaceholder
          thumbnail
          :item="item"
          :thumbSize="{width:300,height:0}"
          :downloadable="false"
          :borderRadius="true"
          :key="'galleryPlaceholder' + item._rev"
          :itemKey="'galleryPlaceholderitem' + item._rev"
          :height="height"
          :cover="true"
        ></GalleryPlaceholder>
      </div>
      <v-card-text class="text--primary wrap-text">{{
        item.title
      }}</v-card-text>
      <v-expand-transition v-if="icon">
        <v-card-actions class="default" :class="{ 'show-bar': hover }">
          <v-spacer></v-spacer>
          <!-- Edit title -->
          <v-menu
            v-model="menuEdit"
            offset-y
            bottom
            transition="scale-transition"
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ on }">
              <v-btn
                small
                icon
                v-on="on"
                title="Edit title"
                :class="{ hidden: !hover && !menuEdit }"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-title>Edit title</v-card-title>
              <v-card-text>
                <v-text-field label="Title" v-model="item.title"></v-text-field>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="menuEdit = false">Cancel</v-btn>
                <v-btn
                  color="primary"
                  @click="
                    menuEdit = false;
                    save();
                  "
                  >Save title</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-menu>

          <!-- Comment -->
          <v-btn
            small
            icon
            title="Comment"
            @click="openCarousel(index)"
            :class="{ hidden: !hover && !menuEdit }"
          >
            <v-icon>mdi-comment-text-outline</v-icon>
          </v-btn>
          <!-- Download -->
          <v-btn
            small
            v-if="item.type != 'link'"
            icon
            title="Download"
            :href="utils.getLink(item)"
            :class="{ hidden: !hover && !menuEdit }"
          >
            <v-icon>mdi-download</v-icon>
          </v-btn>
          <!-- open in new tab if link -->
          <v-btn
            small
            icon
            title="Open link"
            v-if="item.type == 'link'"
            :href="utils.getLink(item)"
            target="_blank"
            :class="{ hidden: !hover && !menuEdit }"
          >
            <v-icon>mdi-open-in-new</v-icon>
          </v-btn>
          <!-- Feature image -->
          <v-btn
            v-if="utils.isImage(item.content_type) && item.type != 'link'"
            small
            icon
            title="Set feature image"
            :class="{ hidden: !hover && !menuEdit }"
            @click="setFeature()"
          >
            <v-icon>mdi-file-presentation-box</v-icon>
          </v-btn>

          <!-- Delete button -->
          <v-btn
            small
            icon
            title="Delete"
            :class="{ hidden: !hover && !menuEdit }"
            @click="remove()"
          >
            <v-icon color="red">mdi-close-circle</v-icon>
          </v-btn>
        </v-card-actions>
      </v-expand-transition>
    </v-card>
  </v-hover>
</template>

<script>
import Spaces from '@/components/services/spaces.js'
import Utility from '@/components/common/utils.js'
import GalleryPlaceholder from '@/components/GalleryPlaceholder.vue'
import * as blobUtil from 'blob-util'
export default {
  name: 'GalleryItem',
  props: {
    view: Object,
    item: Object,
    index: Number,
    collection: Object,
    parentItem: Object,
    parentView: Object,
    height: {
      type: String,
      default: '150px'
    },
    icon: {
      type: Boolean,
      default: true
    }
  },
  data: function () {
    return {
      utils: Utility,
      spaces: Spaces,
      menuEdit: false,
      newParentItem: null
    }
  },
  created () {
    this.newParentItem = Object.assign(this.parentItem)
  },
  methods: {
    async remove () {
      const oldItem = Object.assign(this.item) // shallow clone in case async delete changes
      const repo = this.view.dataSource.repo
      const removed = await repo.removeItem(this.item._id, this)
    },
    save () {
      this.view.put(this.item)
    },
    openCarousel (index) {
      if (this.item.type == 'link') {
        window.open(this.item.url, '_blank')
      } else {
        this.$parent.$emit('openCarousel', index)
      }
    },
    async setFeature () {
      this.newParentItem.featuredAttachment = this.item._id
      await this.parentView.put(this.newParentItem)
    }
  },
  components: {
    GalleryPlaceholder
  }
}
</script>

<style scoped>
.v-card {
  transition: opacity 0.4s ease-in-out;
  cursor: pointer;
}
.show-btns {
  color: rgba(0, 0, 0, 1) !important;
}
.theme--light > .show-bar {
  background-color: rgba(255, 255, 255, 1) !important;
  -webkit-box-shadow: 0px -2px 5px 2px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px -2px 5px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0px -2px 5px 2px rgba(0, 0, 0, 0.1);
}

.theme--dark > .show-bar {
  background-color: rgb(80, 80, 80) !important;
  -webkit-box-shadow: 0px -2px 5px 2px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px -2px 5px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0px -2px 5px 2px rgba(0, 0, 0, 0.21);
}
.default {
  height: 50px;
}
.hidden {
  visibility: hidden;
}
.v-btn,
.v-btn * {
  /* turn off transitions for hide/show on hover */
  transition: none;
}
.wrap-text {
  height: 40px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding: 5px 15px;
}
.show-bar {
  background-color: blue !important;
}
</style>
