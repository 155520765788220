<template>
  <div style="position: relative" v-if="items !== null">
    <div class="headingparent mx-3">
      <DragDropFile
        :disabledrop="disabledropitem"
        :column="column.item"
        class="d-print-none"
        :editor="editormultiple"
        :authorization="authorization"
      >
        <p
          class="font-weight-bold d-print-none"
          :class="
            column.item.title === 'others' ||
            column.item.title === 'completed' ||
            !orderable ||
            !authorization.update ||
            groupBy !== 'heading'
              ? ''
              : 'headitem'
          "
          v-show="groupBy !== 'none'"
          v-if="column.item._id !== 'others'"
        >
          <HeadingEditor
            v-if="groupBy !== 'none'"
            :id="'itemsource' + column.item._id"
            :data-column="column.item._id"
            :data-id="column.item._id"
            :data-index="headingIndex"
            data-type="heading"
            :view="view"
            :item="column.item"
            :properties="properties"
            :authorization="authorization"
            :divider="true"
            @sync="syncSelected"
            @editmode="setEditMode"
          />
        </p>
      </DragDropFile>
      <div
        class="addheadingitem"
        v-if="
          groupBy !== 'none' &&
          column.item._id != 'completed' &&
          column.item._id !== 'others' &&
          !headingEdit
        "
      >
        <v-btn
          small
          icon
          title="Add new item"
          @click="addNewInlineItem(column.item._id)"
          class="addbutton"
          v-if="authorization.update"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
        <v-btn
          small
          icon
          title="Delete Heading"
          @click="deleteHeading(column.item._id)"
          class="deletebutton red--text"
          v-if="authorization.update"
        >
          <v-icon color="red">mdi-close-circle</v-icon>
        </v-btn>
      </div>
    </div>
    <!-- Draggable component comes from vuedraggable. It provides drag & drop functionality -->
    <draggable
      :disabled="!authorization.update"
      :list="items"
      handle=".dragicon"
      ghost-class="ghost"
      @change="orderChange($event, column.item._id, subview)"
      @start="dragStart"
      @end="dragEnd"
      group="itemgroup"
      class="list-group"
      :animation="200"
      :delay="100"
      :delayOnTouchOnly="true"
      :touchStartThreshold="1"
      :move="moved"
      v-bind="dragOptions"
    >
      <template v-for="(source, index, key) in items">
        <drag
          :disabled="!authorization.update"
          :class="'itemsource' + column.item._id"
          :id="'itemsource' + source._id"
          :data-id="source._id"
          :data-column="column.item._id"
          :data-index="headingIndex"
          :data-itemindex="index"
          data-type="item"
          :transfer-data="{ source, viewInbox }"
          :key="'drag' + source._id + source._rev"
          @dragstart="dragStart"
          @dragend="dragEnd"
        >
          <BlogCard
            :ref="'itemref' + source._id"
            @sync="syncSelected"
            @collapseAll="collapseAll"
            :key="'itemlist' + source._id + source._rev"
            :item="source"
            :disabledrop="disabledropitem"
            :perspective="perspective"
            :collectionID="collectionID"
            :account="account"
            :orderable="orderable"
            :view="view"
            :authorization="authorization"
            :isInbox="isInbox"
            :properties="properties"
            :collection="collection"
            :editormultiple="editormultiple"
            :itemindex="index"
            :column="column"
            v-on:openItem="openCarousel"
            :index="index"
          />
        </drag>
      </template>
    </draggable>
    <Carousel
      :visible="galleryDialog"
      @close="galleryDialog = false"
      :view="view"
      :items="reverseSortItems"
      :collection="collection"
      :properties="propertiesFormat"
      :itemIndex="carouselIndex"
      :index.sync="carouselIndex"
      :authorization="authorization"
      v-on:openItemEditor="openDetail"
    />
  </div>
</template>
<script>
import Vue from 'vue'
import { View } from '@/collections'
import BlogItemAdd from '@/components/layouts/BlogItemAdd.vue'
import Carousel from '@/components/Carousel.vue'
export default {
  name: 'BlogHeading',
  components: {
    BlogCard: () => import('@/components/layouts/BlogCard.vue'),
    DragDropFile: () => import('@/components/fileagent/DragDropFile.vue'),
    BlogItemAdd,
    Carousel,
    HeadingEditor: () => import('@/components/HeadingEditor.vue')
  },
  data: function () {
    return {
      disabledropitem: false,
      subview: null,
      headingEdit: false,
      galleryDialog: false,
      carouselIndex: 0
    }
  },
  props: {
    headingIds: { type: Array },
    headingIndex: Number,
    editormultiple: { type: Object },
    print: Boolean,
    selectedId: String,
    isInbox: {
      type: Boolean,
      default: false
    },
    viewInbox: Object,
    disabledrop: {
      type: Boolean,
      default: false
    },
    orderable: {
      type: Boolean,
      default: false
    },
    column: Object,
    authorization: Object,
    collectionID: String,
    account: String,
    view: Object,
    collection: Object,
    perspective: String,
    properties: Object,
    groupBy: {
      type: String,
      default: 'none'
    }
  },
  beforeDestroy () {
    //    console.debug("beforeDestroy subview", this.subview);
    if (this.subview !== null) {
      this.subview.close()
    }
  },
  async created () {
    this.disabledropitem = this.disabledrop || this.perspective == 'views'
    if (this.groupBy === 'none') {
      this.subview = await new View(this.collection).open(
        { ...this.view.vs, ...{ orderable: this.perspective + '_others' } },
        { type: { $ne: 'heading' } }
      )
    } else if (this.groupBy === 'heading') {
      if (this.column.item._id === 'others') {
        this.subview = await new View(this.collection).open(
          { ...this.view.vs, ...{ orderable: this.perspective + '_others' } },
          {
            $or: [
              { heading: { $exists: false } },
              { heading: { $nin: this.arrayOfHeadingIDs } }
            ],
            type: { $ne: 'heading' },
            $not: { completed: true }
          }
        )
      } else if (this.column.item._id === 'completed') {
        this.subview = await new View(this.collection).open(
          { ...this.view.vs, ...{ orderable: this.column.item._id } },
          {
            completed: true
          }
        )
      } else {
        this.subview = await new View(this.collection).open(
          { ...this.view.vs, ...{ orderable: this.column.item._id } },
          {
            heading: this.column.item._id,
            $not: { completed: true }
          }
        )
      }
    } else if (this.groupBy === 'assignee') {
      if (this.column.item._id === 'others') {
        this.subview = await new View(this.collection).open(
          {
            ...this.view.vs,
            ...{ orderable: this.perspective + '_assignee_others' }
          },
          {
            $and: [{ assignee: { $exists: false } }]
          }
        )
      } else {
        this.subview = await new View(this.collection).open(
          { ...this.view.vs, ...{ orderable: this.column.item.title } },
          {
            assignee: this.column.item.title
          }
        )
      }
    } else if (this.groupBy === 'status') {
      if (this.column.item._id === 'others') {
        this.subview = await new View(this.collection).open(
          {
            ...this.view.vs,
            ...{ orderable: this.perspective + '_status_others' }
          },
          {
            $and: [{ status: { $exists: false } }]
          }
        )
      } else {
        this.subview = await new View(this.collection).open(
          { ...this.view.vs, ...{ orderable: this.column.item.title } },
          {
            status: this.column.item.title
          }
        )
      }
    }
  },
  computed: {
    propertiesFormat () {
      return {
        display: {
          fields: {
            title: true,
            description: true
          }
        },
        details: {
          fields: {
            title: 'true',
            description: 'true',
            comment: 'true'
          }
        }
      }
    },
    arrayOfHeadingIDs () {
      return this.headingIds.map((a) => a.item._id)
    },
    dragOptions () {
      return {
        scroll: true,
        scrollSensitivity: 100,
        scrollSpeed: 10
      }
    },
    scrollclass () {
      var self = this
      return function (data) {
        if (data.length == 0) {
          return 'v-scroll-list-none'
        } else if (data.length > 0 && data.length < 10) {
          return 'v-scroll-list-auto'
        } else if (self.groupBy === 'none') {
          return 'v-scroll-list-large'
        }
        return 'v-scroll-list'
      }
    },
    reverseSortItems () {
      if (this.subview !== null) {
        if (this.orderable) {
          return this.subview.items
        } else {
          var items = [...this.subview.items]
          return items.sort(function (a, b) {
            var firstEffectiveDate = new Date(a.effectiveAt).getTime()
            var nextEffectiveDate = new Date(b.effectiveAt).getTime()
            if (isNaN(firstEffectiveDate)) {
              // if no first value then set compare date to effective date
              firstEffectiveDate = new Date(a.createdAt).getTime()
            }
            if (isNaN(nextEffectiveDate)) {
              // if no second value then set compare date to effective date
              nextEffectiveDate = new Date(b.createdAt).getTime()
            }
            return firstEffectiveDate - nextEffectiveDate
          })
        }
      }
      return null
    },
    items: {
      // getter
      get: function () {
        if (this.subview !== null) {
          this.$emit('sync', true)
          if (this.orderable) {
            return this.subview.items
          } else {
            var items = [...this.subview.items]
            return items.sort(function (a, b) {
              var firstEffectiveDate = new Date(a.effectiveAt).getTime()
              var nextEffectiveDate = new Date(b.effectiveAt).getTime()
              if (isNaN(firstEffectiveDate)) {
                // if no first value then set compare date to effective date
                firstEffectiveDate = new Date(a.createdAt).getTime()
              }
              if (isNaN(nextEffectiveDate)) {
                // if no second value then set compare date to effective date
                nextEffectiveDate = new Date(b.createdAt).getTime()
              }
              return nextEffectiveDate - firstEffectiveDate
            })
          }
        }
        return null
      },
      // setter
      set: function (newValue) {
        this.subview.items = newValue
      }
    }
  },
  mounted () {
    let self = this
    self.$root.$on('openCarousel', function (data) {
      self.openCarousel(data.item, data.index)
    })
  },
  methods: {
    openDetail (item) {
      this.$router.push({
        name: 'list',
        params: {
          account: this.account,
          collectionID: this.collectionID,
          perspective: item._id
        }
      })
    },
    openCarousel (data) {
      this.galleryDialog = true
      var getIndex = this.reverseSortItems.findIndex(
        (obj) => obj._id == data.item._id
      )
      this.carouselIndex = getIndex
    },
    moved (evt, originalEvent) {
      if (this.orderable) {
        return true
      } else {
        if (evt.relatedContext.list.length === 0) {
          return true
        }
        var relatedContext = evt.relatedContext.list[0].heading
        var itemHeading = evt.draggedContext.element.heading
        if (
          relatedContext === itemHeading &&
          evt.draggedContext.index !== evt.draggedContext.futureIndex
        ) {
          return false
        } else {
          return true
        }
        return true
      }
    },
    // add item after a selected item if not then add item to first
    addNewInlineItem (heading = '') {
      var self = this
      var newitem = document.getElementById('newitem')
      console.log('Heading item add', newitem)
      if (newitem !== null) return false
      console.log('heading id', heading)
      var ComponentClass = Vue.extend(BlogItemAdd)
      var instance = new ComponentClass({
        parent: self,
        propsData: {
          editor: self.editor,
          view: self.subview,
          columnId: heading,
          columnType: self.groupBy,
          collection: self.collection,
          type: self.type,
          account: self.account,
          perspective: self.perspective,
          viewInbox: self.viewInbox,
          authorization: self.authorization
        }
      })
      instance.$mount()
      var sel = localStorage.getItem('selected')
      if (sel === null) {
        if (heading === '') {
          // with no heading
          var doc = document.getElementsByClassName('list-group')[0]
          doc.before(instance.$el)
        } else {
          var doc = document.getElementsByClassName('itemsource' + heading)[0]
          if (typeof doc !== 'undefined') {
            var index = Number(doc.getAttribute('data-itemindex'))
            instance.index = index - 1
            // heading with items
            doc.before(instance.$el)
          } else {
            // heading with no items
            doc =
              document.getElementsByClassName('list-group')[this.headingIndex]
            doc.before(instance.$el)
          }
        }
      } else {
        var doc = document.getElementById('itemsource' + sel)
        var column = doc.getAttribute('data-column')
        var index = Number(doc.getAttribute('data-itemindex'))
        instance.index = index
        if (column && column == heading) {
          // selecte item and pluse button click on same heading
          doc.after(instance.$el)
        } else {
          instance.index = -1
          // selecte item and pluse button click on different heading
          doc =
            document.getElementsByClassName('list-group')[this.headingIndex]
          doc.before(instance.$el)
        }
      }
      instance.itemSection = true
      setTimeout(() => {
        if (typeof instance.$refs.itemTitle !== 'undefined') {
          instance.$refs.itemTitle.focus()
        }
      }, 100)
      var li = document.querySelectorAll('.navigate')
      for (var i = 0; i < li.length; i++) {
        li[i].classList.remove('selected')
      }
      localStorage.removeItem('selected')
    },
    syncSelected: function (value) {
      this.$emit('update:selectedId', value)
    },
    collapseAll (val) {
      // collapse all except current item right click
      for (var i = 0; i < this.items.length; i++) {
        if (val != this.items[i]._id) {
          this.$refs['itemref' + this.items[i]._id][0].expand = false
        }
      }
    },
    collapseSelected (val) {
      for (var i = 0; i < this.items.length; i++) {
        if (val == this.items[i]._id) {
          this.$refs['itemref' + this.items[i]._id][0].save(false)
        }
      }
    },
    setOrder (id, order) {
      this.subview.setOrder(id, order)
    },
    async moveToGroup (element, column, index) {
      if (this.groupBy == 'status') {
        if (column === 'others') {
          delete element.status
        } else {
          element.status = column
        }
      }
      if (this.groupBy == 'assignee') {
        if (column === 'others') {
          delete element.assignee
        } else {
          element.assignee = column
        }
      }
      if (this.groupBy == 'heading') {
        if (column === 'others') {
          delete element.heading
          if (element.completed) delete element.completed
        } else if (column === 'completed') {
          element.completed = true
        } else {
          element.heading = column
        }
      }
      await this.subview.put(element)
      console.log('index==', index)
      await this.subview.setOrder(element._id, index)
    },
    /**
     * call when drag event completed
     */
    async orderChange (event, column, view) {
      if (typeof event.moved !== 'undefined') {
        var element = event.moved.element
        var newIndex = event.moved.newIndex
        console.log('set order item', element._id, newIndex)
        this.subview.setOrder(element._id, newIndex)
      }
      if (typeof event.added !== 'undefined') {
        var element = event.added.element
        var newIndex = event.added.newIndex
        console.log('set order item', element._id, newIndex)
        if (this.groupBy == 'status') {
          if (column === 'others') {
            delete element.status
          } else {
            element.status = column
          }
        }
        if (this.groupBy == 'assignee') {
          if (column === 'others') {
            delete element.assignee
          } else {
            element.assignee = column
          }
        }
        if (this.groupBy == 'heading') {
          if (column === 'others') {
            delete element.heading
            if (element.completed) delete element.completed
          } else if (column === 'completed') {
            element.completed = true
          } else {
            element.heading = column
          }
        }
        await this.subview.put(element)
        await this.subview.setOrder(element._id, newIndex)
      }
    },
    dragStart (e) {
      console.debug('drag start', e)
      this.disabledropitem = true
      this.$emit('update:disabledrop', this.disabledropitem)
    },
    dragEnd (e) {
      this.disabledropitem = false
      this.$emit('update:disabledrop', this.disabledropitem)
      document.body.click()
    },
    async deleteHeading (itemid) {
      const repo = this.view.dataSource.repo
      const deleted = await repo.removeItem(itemid, this)
      if (!deleted) return
    },
    setEditMode (val) {
      this.headingEdit = val
    }
  },
  watch: {
    disabledrop: {
      handler: function (newVal, oldVal) {
        if (newVal !== null) {
          this.disabledropitem = newVal
        }
      }
    }
  }
}
</script>
<style  scoped>
.list-group {
  min-height: 20px;
}
.item {
  cursor: move;
}
.headitem {
  cursor: move;
}

.list-group {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
  min-height: 50px;
}
.v-scroll-list {
  overflow-y: auto;
  overflow-x: hidden;
  height: 400px;
  width: 100%;
}
.v-scroll-list-none {
  height: 60px;
  width: 100%;
  overflow-x: hidden;
}
.v-scroll-list-auto {
  margin-bottom: 10px;
  height: auto;
  width: 100%;
  overflow-x: hidden;
}
.v-scroll-list-large {
  overflow-y: auto;
  height: 100vh;
  width: 100%;
  overflow-x: hidden;
}
</style>
