<template>
  <div
    @dragover="dragOver"
    @dragenter="dragEnter"
    @dragleave="dragLeave"
    @drop="drop"
    class="is-drag-valid"
    :class="[{ 'is-drag-over': isDragging }, { 'view-drag': item === null }]"
  >
    <div
      v-if="visible"
      v-show="isDragging && item !== null && showText"
      :class="onItem ? 'drag-drop-text-item' : 'drag-drop-text'"
    >
      Drag drop attachment here
    </div>
    <slot> </slot>
  </div>
</template>
<script>
export default {
  name: 'DragDropFile',
  data: function () {
    return { isDragging: false }
  },
  props: {
    editor: { type: Object },
    visible: {
      type: Boolean,
      default: true
    },
    disabledrop: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default: null
    },
    column: {
      type: Object,
      default: null
    },
    allowclick: {
      type: Boolean,
      default: false
    },
    onItem: {
      type: Boolean,
      default: false
    },
    allowdrop: {
      type: Boolean,
      default: true
    },
    authorization: Object,
    showText: {
      type: Boolean,
      default: true
    }
  },
  components: {},
  computed: {
    isMobile () {
      return !!(this.$vuetify.breakpoint.name === 'sm' ||
        this.$vuetify.breakpoint.name === 'xs')
    }
  },
  mounted: function () {},
  methods: {
    isAllowDragDrop () {
      if (
        typeof this.editor !== 'undefined' &&
        this.allowdrop &&
        this.authorization.update
      ) {
        return true
      }
      return false
    },
    dragOver (event) {
      if (this.disabledrop || !this.isAllowDragDrop()) {
        event.preventDefault()
        event.stopPropagation()
      } else {
        this.isDragging = true
        this.editor.dragOver(event)
      }
    },
    dragEnter (event) {
      if (this.disabledrop || !this.isAllowDragDrop()) {
        event.preventDefault()
        event.stopPropagation()
      } else {
        this.isDragging = true
        this.editor.dragEnter(event)
      }
    },
    dragLeave (event) {
      if (this.disabledrop || !this.isAllowDragDrop()) {
        event.preventDefault()
        event.stopPropagation()
      } else {
        this.isDragging = false
        this.editor.dragLeave(event)
      }
    },
    drop (event) {
      var text = event.dataTransfer.getData('URL')
      if (this.disabledrop || !this.isAllowDragDrop()) {
        event.preventDefault()
        event.stopPropagation()
      } else {
        if (text) {
          this.isDragging = false
          event.stopPropagation()
          event.preventDefault()
          this.$emit('dropText', text)
        } else {
          this.isDragging = false
          this.editor.selItem = this.item
          if (this.item !== null) this.editor.selItem = this.item
          if (this.column !== null) this.editor.column = this.column
          this.editor.drop(event)
        }
      }
    },
    click () {
      if (this.disabledrop || !this.isAllowDragDrop()) {
        return false
      } else {
        this.editor.selItem = this.item
        if (this.item !== null) this.editor.selItem = this.item
        if (this.column !== null) this.editor.column = this.column
        this.editor.isClicked = true
        this.editor.click(event)
      }
    },
    paste (file) {
      this.editor.selItem = this.item
      this.editor.isClicked = true
      this.editor.paste(file)
    }
  }
}
</script>
<style scoped>
.file-input-wrapper {
  position: relative;
  border: none;
  min-height: 20px;
  padding: 0px;
}
.drag-drop-text-item {
  position: absolute;
  top: 0;
  left: 0;
  right: 70px;
  width: 100%;
  height: 100%;
  padding: 0px;
  margin: 0;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(250, 250, 250, 0.8);
}
.drag-drop-text {
  margin: 0;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background: rgba(250, 250, 250, 0.8);
}
.is-drag-valid.view-drag.is-drag-over {
  padding: 0px;
  box-shadow: inset 0px 0px 20px 1px #b4b6b3;
}
</style>
