<template>
  <div
    :class="icon ? 'mx-5 mt-5' : 'mx-0'"
    v-if="hasItem"
    :style="icon ? 'width:auto' : 'width:100%'"
  >
    <div :id="'bottompageattachment' + item._id"></div>
    <v-row>
      <v-col class="mt-2">
        <v-icon class="mr-2">mdi-attachment</v-icon>
        <span>Attachments</span><span class="ml-1">({{ hasItem }})</span>
      </v-col>
    </v-row>
    <v-divider />
    <GalleryAttachment
      :parentView="parentView"
      :parentItem="item"
      :collection="collection"
      :attachments="view"
      :authorization="authorization"
      :height="height"
      :icon="icon"
    />
  </div>
</template>

<script>
import GalleryAttachment from '@/components/GalleryAttachment.vue'
import { View } from '@/collections'

export default {
  name: 'Attachment',
  props: {
    collection: Object,
    item: Object,
    authorization: Object,
    parentView: Object,
    height: {
      type: String,
      default: '150px'
    },
    icon: {
      type: Boolean,
      default: true
    }
  },
  data: function () {
    return {
      view: null
    }
  },
  async mounted () {
    this.view = await new View(this.collection).open({
      of: { attached_to: this.item._id }
    })
  },
  computed: {
    hasItem () {
      if (this.view !== null) {
        return this.view.items.length
      }
      return 0
    }
  },
  methods: {
    focus () {
      setTimeout(() => {
        document
          .getElementById('bottompageattachment' + this.item._id)
          .scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'start'
          })
      }, 100)
    }
  },
  components: {
    GalleryAttachment
  }
}
</script>
