<template>
    <v-menu top offset-y>
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          color="primary"
          :icon="!text"
          :fab="!text"
          :text="text"
          :block="text"
          dark
          x-small
          title="Add new layout"
          class="float-right"
        >
          <v-icon>mdi-plus</v-icon><span v-if="text"> Add</span>
        </v-btn>
      </template>
      <v-list style="max-height: 190px;min-width:180px" class="overflow-y-auto layoutlist">
        <v-list-item v-for="template in templates" :key="template.type" @click="openNew(template)">
          <v-list-item-title class="mx-4">{{ utils.ucfirst(template.type) }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
</template>

<script>
import templates from '@/components/json/templates.json'
import Utility from '@/components/common/utils.js'

export default {
  name: 'AddLayoutButton',
  data: () => ({
    templates: templates,
    utils: Utility
  }),
  components: {},
  props: {
    authorization: Object,
    text:{
      type:Boolean,
      default:false
    }
  },
  computed: {},
  created () {},
  mounted () {},
  methods: {
    async openNew (template) {
      this.$emit('open', template)
    }
  },
  watch: {}
}
</script>

<style lang="scss">
.layoutlist .v-list-item {
  min-height: 34px !important;
  padding: 0 10px !important;
}
</style>
