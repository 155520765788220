<!-- Component to edit an item -->

<template>
  <section id="vieweditor" class="toparea mt-1">
    <div v-if="item && (perspective != 'views' || mode === 'new')">
      <h1 class="text-center d-none d-print-block" style="font-size: 25pt">
        {{ item.title }}
      </h1>
      <div :class="isMobile ? 'd-inline-block mb-5 pb-5' : ''">
        <div
          :class="editordrawer ? 'opendrawersave' : 'closedrawersave'"
          class="d-print-none"
        >
          <v-btn
            v-if="isMobile"
            icon
            class="m-t-8"
            @click.native="save"
            v-show="authorization.update && mode === 'new'"
            :color="changeSave ? 'primary' : ''"
          >
            <v-icon>mdi-check</v-icon>
          </v-btn>
        </div>
        <div
          :class="editordrawer ? 'opendrawer' : 'closedrawer'"
          class="d-print-none"
        >
          <v-menu bottom offset-y v-if="layouts && item._id">
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                class="d-inline-block opac-btn"
                v-on="on"
                title="Layout"
              >
                <v-icon>mdi-collage</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="layout in layouts"
                :key="layout.text"
                @click="switchLayout(layout)"
              >
                <v-list-item-title>
                  {{ utils.ucfirst(layout.text) }}
                  <v-icon
                    color="green"
                    v-if="layout.text == selectedLayout.text"
                    class="ml-2"
                    small
                    >mdi-check</v-icon
                  >
                </v-list-item-title>
              </v-list-item>
              <v-list-item v-if="authorization.update">
                <AddLayoutButton
                  :authorization="authorization"
                  @open="addViewTemplate"
                  text
                />
              </v-list-item>
            </v-list>
          </v-menu>
          <v-btn
            v-if="!isMobile && authorization.update && mode === 'new'"
            title="Save"
            icon
            class="d-inline-block opac-btn"
            @click.native="save"
            :color="changeSave ? 'primary' : ''"
          >
            <v-icon>mdi-check</v-icon>
          </v-btn>
          <v-btn
            title="Share"
            icon
            class="d-inline-block opac-btn"
            v-if="item._id && isOwner"
            @click="openShareDialog()"
          >
            <v-icon>mdi-share-variant</v-icon>
          </v-btn>
          <v-menu bottom offset-y>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                class="d-inline-block opac-btn"
                v-on="on"
                title="More"
              >
                <v-icon>mdi-dots-horizontal</v-icon>
              </v-btn>
            </template>
            <v-list dense class="ma-0 pa-0">
              <v-list-item
                class="ma-0 pa-0"
                v-if="
                  item._id &&
                  isOwner &&
                  collection.info.defaultPage &&
                  item._id == collection.info.defaultPage
                "
              >
                <v-btn
                  title="Edit area"
                  text
                  small
                  tile
                  block
                  class="left-btn d-inline-block"
                  @click="openEditCollection()"
                >
                  <v-icon class="mr-2">mdi-pencil</v-icon>Edit area
                </v-btn>
              </v-list-item>
              <v-list-item
                class="ma-0 pa-0"
                v-if="
                  item._id &&
                  groupBy === 'heading' &&
                  authorization.update &&
                  layout == 'list'
                "
              >
                <v-btn
                  text
                  small
                  tile
                  block
                  class="left-btn d-inline-block"
                  title="Add New Heading"
                  @click="addHeading()"
                >
                  <v-icon class="mr-2">mdi-page-layout-header</v-icon>Add New
                  Heading
                </v-btn>
              </v-list-item>
              <v-list-item class="ma-0 pa-0" v-if="item._id">
                <v-btn
                  title="Print"
                  text
                  small
                  tile
                  block
                  class="left-btn d-inline-block"
                  @click="printing()"
                >
                  <v-icon class="mr-2">mdi-printer</v-icon>Print
                </v-btn>
              </v-list-item>
              <v-list-item
                class="ma-0 pa-0"
                v-if="item._id && authorization.update"
              >
                <v-btn
                  title="Delete"
                  text
                  small
                  tile
                  block
                  class="left-btn d-inline-block"
                  @click="remove"
                >
                  <v-icon color="red" class="mr-2">mdi-close-circle</v-icon
                  >Delete
                </v-btn>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-btn
            icon
            class="d-inline-block opac-btn ml-4"
            @click="
              editordrawer = !editordrawer;
              checkDrawerState();
            "
            title="Show/Hide inspector"
            v-if="!editordrawer"
          >
            <v-icon>mdi-information</v-icon>
          </v-btn>
        </div>
      </div>
      <v-navigation-drawer
        class="d-print-none"
        v-model="editordrawer"
        app
        clipped
        right
        mobile-breakpoint="769"
        :disable-resize-watcher="true"
        :touchless="true"
        v-outside-click="saveQuit"
        @input="checkDrawerState"
        height="100%"
      >
        <template v-slot:prepend>
          <div class="text-center ma-2 align-self-center justify-center d-flex">
            <span class="text-h6">Collection Details</span>
            <v-btn
              icon
              @click.stop="
                editordrawer = !editordrawer;
                changeDrawerState();
              "
              class="editor-close d-inline-block"
              v-if="editordrawer"
              title="Show/Hide inspector"
            >
              <v-icon>mdi-information</v-icon>
            </v-btn>
          </div>
        </template>
        <v-row no-gutters class="pa-0 ma-0">
          <v-col cols="12" class="text-center">
            <div class="ma-4">
              <div class="mt-2 mb-2 subtitle-2 text-left">
                <span class="d-block font-weight-regular" v-if="item.createdBy">
                  <b>Author:</b>
                  {{ userInfo.display }}
                </span>
                <span class="d-block font-weight-regular" v-if="item.createdAt">
                  <b>Created:</b>
                  {{
                    utils.formatDateTime(item.createdAt, "DD/MMM/YYYY hh:mm A")
                  }}
                </span>
                <span class="d-block font-weight-regular" v-if="item.updatedAt">
                  <b>Updated:</b>
                  {{
                    utils.formatDateTime(item.updatedAt, "DD/MMM/YYYY hh:mm A")
                  }}
                </span>
              </div>
              <div>
                <CollectionDefaultPage
                  v-if="item._id && isOwner"
                  :collection="collection"
                  :item="item"
                  :repo="repo"
                />
                <v-checkbox
                  v-model="item.orderable"
                  class="my-2 menu-btn"
                  label="Manually Orderable"
                  hide-details
                ></v-checkbox>
                <v-checkbox
                  v-model="item.showDescription"
                  class="my-2 menu-btn"
                  label="Show Description"
                  hide-details
                ></v-checkbox>
              </div>
              <div>
                <v-select
                  :items="groups"
                  item-text="text"
                  item-value="value"
                  v-model="item.groupBy"
                  label="Group by"
                />
              </div>
              <!-- COMMENTED show others, may be remove it later
                <div v-show="item.groupBy !== 'none'">
                <v-select
                  :items="showOthers"
                  item-text="text"
                  item-value="value"
                  v-model="item.show_others"
                  label="Show others"
                />
              </div> -->
            </div>
            <div class="mx-4 mt-5 mb-5 text-left">
              <v-row>
                <v-col cols="12">
                  <v-icon class="mr-2">mdi-page-layout-body</v-icon>
                  <span>Layouts</span>
                  <AddLayoutButton
                    :authorization="authorization"
                    @open="addViewTemplate"
                    class="float-right"
                    v-if="authorization.update"
                  />
                </v-col>
              </v-row>
              <v-divider />
              <LayoutEditor :authorization="authorization" :item.sync="item" />
            </div>
            <div
              class="mx-4 mb-5 text-left"
              v-if="utils.featureIsEnabled(currentUser)"
            >
              <v-row>
                <v-col cols="12" class="mt-2">
                  <v-icon class="mr-2">mdi-filter</v-icon>
                  <span>Filter</span>
                </v-col>
              </v-row>
              <v-divider />
              <filter-builder
                :view="view"
                v-model="filterData"
                @sync="syncData"
              />
            </div>
          </v-col>
        </v-row>
      </v-navigation-drawer>
      <div class="ma-0 pa-0 uncover">
        <div class="maincontainer">
          <div class="maincontainer">
            <v-row no-gutters>
              <v-col cols="12">
                <div class="d-flex d-print-none">
                  <v-textarea
                    label="Title here..."
                    auto-grow
                    solo
                    rows="1"
                    row-height="15"
                    flat
                    @blur="saveTitle()"
                    ref="itemTitle"
                    style="font-size: 1.4rem"
                    class="
                      custom-input-multiline
                      font-weight-bold
                      h1
                      text-center
                    "
                    background-color="transparent"
                    :readonly="!authorization.update"
                    v-model="item.title"
                    hide-details
                    :class="!isMobile ? 'margin-big' : ''"
                  />
                </div>
                <!-- Description area -->
                <div
                  class="mt-2 d-flex"
                  v-if="item.showDescription && layout !== 'board'"
                >
                  <text-area
                    :autoheight="false"
                    :authorization="authorization"
                    v-model="item.body"
                    class="texteditor"
                    @attachment="syncAttachment"
                    :collection="collection"
                  />
                </div>
              </v-col>
            </v-row>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { View } from '@/collections'
import Utils from '@/components/common/utils.js'
import filterBuilder from '@/components/FilterBuilder.vue'
import lodash from 'lodash'
import TextArea from '@/components/common/markdownit/text-area.vue'
import templates from '@/components/json/templates.json'
import LayoutEditor from '@/components/LayoutEditor.vue'
import AddLayoutButton from '@/components/AddLayoutButton.vue'
import CollectionDefaultPage from '@/components/CollectionDefaultPage.vue'
import { OutsideClick } from '@/directives/OutsideClick.js'
import _attachment from '@/components/common/attachment.js'
export default {
  name: 'ViewEditor',
  data: function () {
    return {
      view: null,
      utils: Utils,
      options: {
        debug: false,
        disableAll: false,
        autoFoldObjects: true
      },
      filterData: { container: '${_id}' },
      olditem: null,
      templates: templates,
      groups: [
        { text: 'None', value: 'none' },
        { text: 'Assignee', value: 'assignee' },
        { text: 'Status', value: 'status' },
        { text: 'Heading', value: 'heading' }
      ],
      showOthers: [
        { text: 'Above', value: 'above' },
        { text: 'Below', value: 'below' },
        { text: 'Hide', value: 'hide' }
      ],
      userInfo: {},
      attachmentInfo: [],
      editordrawer: false
    }
  },
  props: {
    print: {
      type: Boolean,
      default: false
    },
    layout: {
      type: String,
      default: ''
    },
    editing: {
      type: Boolean,
      default: false
    },
    authorization: Object,
    collection: Object,
    selectedView: Object,
    perspective: String,
    collectionID: String,
    mode: String,
    account: String,
    isOwner: {
      type: Boolean,
      default: false
    },
    repo: Object,
    currentUser: Object,
    layouts: Array,
    selectedLayout: Object
  },
  directives: {
    OutsideClick
  },
  components: {
    filterBuilder,
    TextArea,
    LayoutEditor,
    AddLayoutButton,
    CollectionDefaultPage
  },
  async created () {
    try {
      this.view = await new View(this.collection).open('views')
    } catch (err) {
      console.debug(err)
    }
    this.openOn()
  },
  destroyed () {
    document.removeEventListener('keydown', this.keyListener)
  },
  methods: {
    keyListener (e) {
      if (e.key === '~' && (e.ctrlKey || e.metaKey) && this.item !== null) {
        e.preventDefault() // present browser event trigger
        console.log(this.item)
      }
    },
    focusInput () {
      this.$nextTick(() => {
        this.$refs.itemTitle.focus()
      })
    },
    openNew () {
      this.$router.push({
        name: 'list',
        params: {
          account: this.account,
          collectionID: this.collectionID,
          perspective: this.perspective,
          mode: 'new'
        }
      })
    },
    update () {},
    async openOn () {
      this.olditem = this.selectedView
      // REVIEW animesh not sure intent of this function before, but olditem was
      // undefined in some cases causing crash when referencing createdBy
      if (this.oldItem) {
        this.userInfo = await this.utils.getUserDetails(
          this.collection,
          this.olditem.createdBy
        )
        if (this.olditem.hasOwnProperty('of')) {
          this.filterData = this.olditem.of
        }
      }
    },

    async remove () {
      const removed = await this.repo.removeItem(this.item._id, this)
      if (removed) {
        this.item = null
        this.olditem = null
        this.$emit('delete')
        this.$router.push({
          name: 'list',
          params: {
            account: this.account,
            collectionID: this.collectionID
          }
        })
      }
    },
    async saveTitle () {
      if (this.olditem.title !== this.item.title) {
        console.log('save title')
        await this.view.put(this.item)
        this.olditem.title = this.item.title
      }
    },
    async save () {
      if (this.item) {
        this.item.of = this.filterData
        await this.view.put(this.item)
        if (this.attachmentInfo.length > 0) {
          await _attachment.add(
            this.item,
            this.attachmentInfo,
            this.collection
          )
        }
      }
    },
    async saveQuit () {
      if (this.changeSave) {
        await this.save()
      }
    },
    syncData (value) {
      if (Object.keys(value).length === 0) {
        this.filterData = {
          container: '${_id}'
        }
      } else this.filterData = value
    },
    openShareDialog () {
      this.$parent.openMembershipDialog()
    },
    openEditCollection () {
      this.$parent.$parent.$parent.$parent.openEditCollection(
        this.collection.info
      )
    },
    addViewTemplate (template) {
      if (!this.item.hasOwnProperty('layouts')) {
        this.item.layouts = []
      }
      this.item.layouts.push(template)
    },
    printing () {
      var self = this
      this.$emit('update:print', true)
      this.$nextTick(() => {
        window.print()
        self.$emit('update:print', false)
      })
    },
    changeDrawerState () {
      this.saveQuit()
      this.$emit('updateEditordrawer', this.editordrawer)
    },
    checkDrawerState () {
      this.$emit('updateEditordrawer', this.editordrawer)
    },
    syncAttachment (value) {
      for (var i = 0; i < value.length; i++) {
        this.attachmentInfo.push(value[i])
      }
    },
    addHeading () {
      this.$emit('addHeading')
    },
    switchLayout (layout) {
      this.$parent.$parent.$parent.$parent.switchLayout(layout)
    }
  },
  watch: {},
  mounted () {
    document.addEventListener('keydown', this.keyListener)
  },
  computed: {
    changeSave () {
      if (
        !lodash.isEqual(this.olditem, this.item) &&
        this.authorization.update
      ) {
        this.$emit('update:editing', true)
        return true
      }
      return false
    },
    newItem () {
      return this.item
    },
    item: {
      get: function () {
        return this.view && this.view.itemMap[this.perspective]
      },
      set: function (newValue) {
        this.$emit('update:selectedView', newValue)
      }
    },
    isMobile () {
      return !!(
        this.$vuetify.breakpoint.name === 'sm' ||
        this.$vuetify.breakpoint.name === 'xs'
      )
    },
    allTemplates () {
      return this.templates.map((g) => g.type)
    },
    groupBy () {
      if (this.perspective === '_inbox') {
        return 'none'
      } else if (
        typeof this.item !== 'undefined' &&
        (this.item.groupBy || typeof this.item.groupBy === 'string')
      ) {
        return this.item.groupBy
      }
      return 'none'
    }
  }
}
</script>

<style scoped>
#foo {
  width: 200px;
}
.v-dialog > .v-card > .v-toolbar {
  position: sticky;
  top: 0;
  z-index: 999;
}
.v-subheader {
  height: auto;
  padding: 15px 15px;
}
.menu-btn {
  min-width: 180px !important;
  justify-content: left !important;
}
.itemtoolbar >>> .v-toolbar__content {
  padding: 4px 5px;
}
.opendrawer {
  position: absolute;
  z-index: 2;
  right: 10px;
  top: 8px;
}
.closedrawer {
  position: absolute;
  z-index: 2;
  right: 10px;
  top: 8px;
}
.opendrawersave {
  position: absolute;
  z-index: 2;
  right: 336px;
  top: 8px;
}
.closedrawersave {
  position: absolute;
  z-index: 1;
  right: 80px;
  top: 0;
}
.opendraweradd {
  position: fixed;
  z-index: 1;
  right: 20px;
  bottom: 15px;
}
.closedraweradd {
  position: fixed;
  z-index: 1;
  right: 20px;
  bottom: 15px;
}
@media only screen and (max-width: 959px) {
  .closedrawersave {
    right: 10px;
  }
  .opendrawersave {
    right: 10px;
  }
}
.is-drag-valid.is-drag-over {
  padding: 0px;
  border: 1px dashed #aaaaaa;
  border-color: #b4b6b3;
  box-shadow: inset 0px 0px 20px 1px #b4b6b3;
}
.editor-close {
  position: absolute;
  right: 10px;
  top: 7px;
}
.texteditor {
  padding: 5px 5px;
}
.margin-big {
  margin: 0 170px;
}
</style>
