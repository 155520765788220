   <template>
  <div>
    <div class="vue-lb-container" v-if="dialog" @click="dialog=false"></div>
    <div class="ma-0 pa-0 parentcontainer" v-if="dialog">
      <v-row no-gutters class="hidden-xs-only" @keydown="navigate">
        <v-col :cols="12">
          <v-slide-group v-model="carouselIndex" center-active mandatory>
            <v-slide-item
              v-for="(item, key) in items"
              :key="'galleryCarouselSlide_' + key"
              v-slot:default="{ active, toggle }"
            >
              <v-card
                flat
                :color="bgcolor"
                class="my-1 no-round"
                height="150"
                width="150"
                @click="toggle"
              >
                <div
                  class="thumbnail cimg"
                  :class="active ? 'thumb-active' : ''"
                >
                  <CarouselPlaceHolder
                    :view="view"
                    :item="item"
                    :downloadable="false"
                    :height="'150'"
                    :thumbnail="true"
                    :key="'galleryPlaceholderSlide' + item._rev"
                    :collection="collection"
                    :thumbSize="{ width: 300, height: 300 }"
                    :properties="properties"
                    @hasImage="hasImage(item._id, ...arguments)"
                  ></CarouselPlaceHolder>
                </div>
              </v-card>
            </v-slide-item>
          </v-slide-group>
        </v-col>
      </v-row>
      <v-row no-gutters :class="[controlbar, bgcolor]">
        <v-col cols="12" class="pa-2" style="border-bottom: 1px solid #aaa">
          <div class="float-right">
            <v-btn fab x-small color="primary" @click="carouselIndex--">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <v-btn
              fab
              class="ml-3"
              x-small
              color="primary"
              @click="carouselIndex++"
            >
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
            <v-btn
              fab
              class="ml-3"
              x-small
              color="primary"
              :href="
                items[carouselIndex] ? utils.getLink(items[carouselIndex]) : ''
              "
            >
              <v-icon>mdi-download</v-icon>
            </v-btn>
            <v-btn
              @click="openItemEditor(items[carouselIndex])"
              fab
              class="ml-3"
              x-small
              color="primary"
            >
              <v-icon>mdi-arrow-expand</v-icon>
            </v-btn>
            <v-btn
              fab
              class="ml-3"
              x-small
              color="primary"
              @click="dialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
          <div class="text-center itemtitle" :class="controltitle">
            <span class="title">{{
              items[carouselIndex] ? items[carouselIndex].title : ""
            }}</span>
          </div>
        </v-col>
      </v-row>
      <v-row no-gutters class="carousel text-center" :class="carousel">
        <v-col
          class="canvascontainer pa-1"
          :class="bgcolor"
          :cols="cols(8)"
          :xl="cols(8)"
          :lg="cols(8)"
          :md="cols(7)"
          :sm="cols(7)"
        >
          <div
            id="galleryCanvasArea"
            style="position: relative; height: 100%; width: 100%"
          >
            <v-carousel
              :dark="$vuetify.theme.dark"
              :light="!$vuetify.theme.dark"
              hide-delimiters
              v-model="carouselIndex"
              height="100%"
              hide-delimiter-background
              class="custom"
              :show-arrows="false"
            >
              <v-carousel-item
                position="center"
                v-for="(item, key) in items"
                :key="'galleryCarousel_' + key"
              >
                <v-row
                  no-gutters
                  class="fill-height"
                  align="center"
                  justify="center"
                >
                  <CarouselPlaceHolder
                    :view="view"
                    :item="item"
                    :itemKey="'galleryCarousel_' + key"
                    :key="'galleryPlaceholderCarousel' + item._rev"
                    areakey="galleryCanvasArea"
                    :downloadable="false"
                    :parentKey="'galleryCarousel_' + key"
                    :original="true"
                    :thumbnail="false"
                    :collection="collection"
                    :properties="properties"
                  ></CarouselPlaceHolder>
                </v-row>
              </v-carousel-item>
            </v-carousel>
          </div>
        </v-col>
        <v-col
          :cols="cols(4)"
          :xl="cols(4)"
          :lg="cols(4)"
          :md="cols(5)"
          :sm="cols(5)"
          v-if="
            properties.details.fields.title ||
            properties.details.fields.description ||
            properties.details.fields.comment
          "
        >
          <div class="hidden-xs-only pa-2 text-right">
            <v-btn fab x-small color="primary" @click="carouselIndex--">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <v-btn
              fab
              class="ml-3"
              x-small
              color="primary"
              @click="carouselIndex++"
            >
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
            <v-btn
              fab
              class="ml-3"
              x-small
              color="primary"
              v-if="
                firstImage &&
                items[carouselIndex] &&
                firstImage[items[carouselIndex]._id]
              "
              :href="utils.getLink(firstImage[items[carouselIndex]._id])"
              :download="true"
            >
              <v-icon>mdi-download</v-icon>
            </v-btn>
            <v-btn
              @click.stop="openItemEditor(items[carouselIndex])"
              fab
              class="ml-3"
              x-small
              color="primary"
            >
              <v-icon>mdi-arrow-expand</v-icon>
            </v-btn>
            <v-btn
              fab
              class="ml-3"
              x-small
              color="primary"
              @click="dialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
          <div class="scroll-div">
            <div
              v-for="(item, index) in items"
              :key="'galleryDetails_' + index"
            >
              <div class="text-left" v-if="carouselIndex === index">
                <GalleryDetailsEditor
                  :key="'carouselgalleryEditor' + item._rev"
                  :view="view"
                  :item="item"
                  :properties="properties"
                  :authorization="authorization"
                  :collection="collection"
                />
                <InlineComment
                  :collection="collection"
                  ref="comment"
                  :item="item"
                  :key="'gallery_comment' + item._rev"
                  v-if="item._id && properties.details.fields.comment"
                  :authorization="authorization"
                  :autoScroll="false"
                  class="px-2"
                />
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import { View } from '@/collections'
import CarouselPlaceHolder from '@/components/CarouselPlaceHolder.vue'
import GalleryDetailsEditor from '@/components/layouts/GalleryDetailsEditor.vue'
import InlineComment from '@/components/InlineComment.vue'
import Utility from '@/components/common/utils.js'
import Vue from 'vue'

export default {
  name: 'Carousel',
  components: {
    InlineComment,
    CarouselPlaceHolder,
    GalleryDetailsEditor
  },
  props: {
    items: Array,
    authorization: Object,
    visible: {
      type: Boolean,
      default: function () {
        return false
      }
    },
    collection: Object,
    properties: Object,
    view: Object,
    itemIndex: Number
  },
  data () {
    return {
      window: {
        width: 0,
        height: 0
      },
      utils: Utility,
      test: 10,
      firstImage: []
    }
  },
  mounted () {
    this._keyListener = function (e) {
      if (e.key === 'Escape') {
        e.preventDefault() // present browser event trigger
        this.dialog = false
      }
    }
    document.addEventListener('keydown', this._keyListener.bind(this))
  },
  computed: {
    dialog: {
      get: function () {
        return this.visible
      },
      set: function (value) {
        if (!value) {
          this.firstImage = []
          this.$emit('close')
        }
      }
    },
    carouselIndex: {
      get: function () {
        return this.itemIndex
      },
      set: function (value) {
        this.$emit('update:index', value)
      }
    },
    bgcolor () {
      return this.$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-1'
    },
    controlbar () {
      if (
        this.properties.details.fields.title ||
        this.properties.details.fields.description ||
        this.properties.details.fields.comment
      ) {
        return 'hidden-sm-and-up'
      }
      return ''
    },
    carousel () {
      if (
        this.properties.details.fields.title ||
        this.properties.details.fields.description ||
        this.properties.details.fields.comment
      ) {
        return 'wt-carousel'
      }
      return 'wot-carousel'
    },
    carouselplaceholder () {
      if (
        this.properties.details.fields.title ||
        this.properties.details.fields.description ||
        this.properties.details.fields.comment
      ) {
        return 'wt-carouselplaceholder'
      }
      return 'wot-carouselplaceholder'
    },
    controltitle () {
      if (
        this.properties.details.fields.title ||
        this.properties.details.fields.description ||
        this.properties.details.fields.comment
      ) {
        return 'd-none'
      }
      return ''
    }
  },
  destroyed () {
    window.removeEventListener('resize', this.handleResize)
  },
  created () {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  methods: {
    hasImage (_id, val) {
      Vue.set(this.firstImage, _id, val.item)
    },
    handleResize () {
      this.window.width = window.innerWidth
      this.window.height = window.innerHeight
    },
    cols (no) {
      if (
        this.properties.details.fields.title ||
        this.properties.details.fields.description ||
        this.properties.details.fields.comment
      ) {
        switch (this.$vuetify.breakpoint.name) {
          case 'sm':
          case 'md':
          case 'lg':
          case 'xl':
            return no
          default:
            return '12'
        }
      } else {
        return '12'
      }
    },
    navigate (event) {
      console.log(event.keyCode)
      switch (event.keyCode) {
        case 38:
        case 39:
          this.carouselIndex++
          break
        case 40:
        case 37:
          this.carouselIndex--
          break
      }
    },
    openItemEditor (item) {
      this.$emit('openItemEditor', item)
    }
  }
}
</script>

<style scoped>
.vue-lb-container {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  box-sizing: border-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  padding: 10px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2000;
  -webkit-align-items: center;
  -moz-box-sizing: border-box;
  -webkit-justify-content: center;
  -ms-flex-align: center;
  -webkit-box-align: center;
  -ms-flex-pack: center;
  -webkit-box-pack: center;
}
.wt-carouselplaceholder {
  /*  max-width: 340px; */
  margin: 0 auto;
}
.wot-carouselplaceholder {
  /*  max-width: 340px; */
  /* float: left; */
  display: inline-block;
}
.carouselplaceholder {
  height: 100%;
  overflow: hidden;
  position: relative;
  min-height: 200px;

  justify-content: center;
  vertical-align: middle;
  align-items: center;
  text-align: center !important;
}
.wt-carousel {
  /* display: inline-block; */
}
.wot-carousel {
  width: 100%;
  display: inline-block;
}
.carousel {
  position: relative;
  margin: 0 auto;
  padding: 0px;
}
.scroll-div {
  height: calc(100vh - 300px);
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 5px;
}
.theme--light .carousel {
  background-color: #bdbdbd;
}
.theme--dark .carousel {
  background-color: #424242;
}
.canvascontainer {
  height: calc(100vh - 250px);
}
@media (min-width: 2000px) {
  .wt-carouselplaceholder {
    /* max-width: 50%; */
  }
  .wot-carouselplaceholder {
    /*  max-width: 50%; */
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .wot-carouselplaceholder {
    width: 100%;
  }
  .carousel {
    overflow-y: auto;
  }
  .wot-carousel {
  }
  .wt-carousel {
    height: calc(100vh - 100px);
  }
  .scroll-div {
    height: 100%;
    overflow: hidden;
  }
  .itemtitle {
    width: 100%;
    margin-left: 0px !important;
    display: inline-block;
    padding-top: 10px;
  }
  .parentcontainer {
    width: 100% !important;
  }
}
.close-btn {
  position: absolute;
  top: 20px;
  right: 20px;
}

.thumb-active {
  opacity: 0.15;
}
.arrow-left {
  position: absolute;
  bottom: 10px;
  right: 70px;
}
.arrow-right {
  position: absolute;
  bottom: 10px;
  right: 10px;
}
.no-round {
  border-radius: 0 !important;
}
.v-carousel {
  width: auto !important;
}
.itemtitle {
  height: 50px;
  margin-left: 160px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.parentcontainer {
  width: 90%;
  position: fixed;
  z-index: 3000;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.cimage div img {
  object-fit: cover;
}
.cimage {
  text-align: center;
}
</style>
