<template>
  <v-row no-gutters class="ma-0 pa-0">
    <v-col cols="12" v-if="collection !== null && collection.isOpen">
      {{ collection["@dbid"] }}
      <div v-if="!errorView">
        <div v-if="perspective == '_inbox'">
          <div v-if="mode === ''">
            <h1 class="text-center my-2" style="font-size: 1.3rem">Inbox</h1>
            <List
              :authorization="authorizationinbox"
              :perspective="perspective"
              :collectionID="collectionID"
              :account="account"
              :viewInbox="viewInbox"
              :collection="viewInboxCollection"
              :key="'inbox' + refreshKey"
            />
          </div>
          <div v-else>
            <ItemEditor
              ref="editor"
              :editing="isEditing"
              @updateEditordrawer="updateEditordrawer"
              :mode="mode"
              :perspective="perspective"
              :collectionID="collectionID"
              :account="account"
              :viewInbox="viewInbox"
              :collection="viewInboxCollection"
              :authorization="authorizationinbox"
              :collectionMembersView="collectionMembersView"
              :repo="repo"
              :currentUser="currentUser"
            />
          </div>
        </div>
        <div
          v-else-if="
            mode === '' &&
            ((selectedView && Object.keys(selectedView).length !== 0) ||
              perspective == 'views')
          "
        >
          <ViewEditor
            :editing="isEditing"
            :print.sync="isPrinting"
            ref="vieweditor"
            :layout="selectedLayout.text"
            @updateEditordrawer="updateEditordrawer"
            :account="account"
            :mode="mode"
            :collectionID="collectionID"
            :perspective="perspective"
            :selectedView.sync="selectedViewSync"
            :authorization="authorization"
            :collection="collection"
            :key="'view' + refreshKey"
            :isOwner="isOwner"
            :repo="repo"
            :currentUser="currentUser"
            @addHeading="addHeading"
            @delete="onDeleteView"
            :layouts="layouts"
            :selectedLayout="selectedLayout"
          />
          <div
            class="d-print-none"
            :class="editordrawerSync ? 'opendraweradd' : 'closedraweradd'"
            v-if="perspective != 'views'"
            v-show="authorization.update"
          >
            <v-btn
              fab
              dark
              color="primary"
              @click="openNew()"
              v-show="selectedLayout.text === 'board'"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </div>
          <div v-if="mode === ''">
            <div v-if="perspective === 'views'">
              <div class="buttonarea">
                <v-btn
                  v-if="
                    isPublicCollection &&
                    !isOwner &&
                    !hasMembership &&
                    utils.featureIsEnabled(currentUser)
                  "
                  title="Bookmark"
                  icon
                  class="d-inline-block opac-btn"
                  @click="saveBookmark"
                >
                  <v-icon>mdi-bookmark</v-icon>
                </v-btn>
                <v-btn
                  v-if="isOwner"
                  title="Edit area"
                  icon
                  class="d-inline-block opac-btn"
                  @click="openEditCollection"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn
                  v-if="isOwner"
                  title="Membership"
                  icon
                  class="d-inline-block opac-btn"
                  @click="openMembershipDialog"
                >
                  <v-icon>mdi-share-variant</v-icon>
                </v-btn>
              </div>
            </div>
            <component
              :editordrawer="editordrawerSync"
              ref="layout"
              :print.sync="isPrinting"
              :account="account"
              :is="selectedLayout.text"
              :layout="selectedLayout.text"
              :collection="collection"
              :perspective="perspective"
              :properties="selectedLayout.properties"
              :selectedView.sync="selectedViewSync"
              :authorization="authorization"
              :collectionID="collectionID"
              :key="'layout' + refreshKey + selectedLayout.text"
              :collectionMembersView="collectionMembersView"
            />
          </div>
        </div>
        <div v-else>
          <ItemEditor
            ref="editor"
            :editing="isEditing"
            @updateEditordrawer="updateEditordrawer"
            :account="account"
            :mode="mode"
            :collectionID="collectionID"
            :perspective="perspective"
            :collection="collection"
            :authorization="authorization"
            :collectionMembersView="collectionMembersView"
            :isOwner="isOwner"
            :repo="repo"
            :key="'itemEditor' + perspective"
            :currentUser="currentUser"
          />
        </div>
        <MembershipShareDialog
          ref="membership"
          :collection="collection"
          :usersView="usersView"
          :repo="repo"
          :collectionMembersView="collectionMembersView"
        />
      </div>
      <div class="text-center" v-else>
        <h2>Error Selecting View</h2>
      </div>
    </v-col>
  </v-row>
</template>
<script>
import List from '@/components/layouts/ItemList.vue'
import Board from '@/components/layouts/Board.vue'
import Gallery from '@/components/layouts/Gallery.vue'
import Blog from '@/components/layouts/BlogList.vue'
import ItemEditor from '@/components/ItemEditor.vue'
import ViewEditor from '@/components/ViewEditor.vue'
import Utility from '@/components/common/utils.js'
import MembershipShareDialog from '@/components/MembershipShareDialog.vue'
import DragDropFile from '@/components/fileagent/DragDropFile.vue'

export default {
  data: () => ({
    view: null,
    fab: false,
    isEditing: false,
    isPrinting: false,
    utils: Utility,
    isViewDeleted: false,
    editordrawer: false
  }),
  props: {
    errorView: {
      type: Boolean,
      default: false
    },
    selectedView: Object,
    collection: Object,
    perspective: String,
    viewInbox: Object,
    authorization: Object,
    collectionID: String,
    mode: String,
    account: String,
    selectedLayout: {
      type: Object,
      default: {
        type: 'list',
        properties: {}
      }
    },
    membershipsView: Object,
    usersView: Object,
    repo: Object,
    currentUser: Object,
    collectionMembersView: Object,
    layouts: Array
  },
  components: {
    List,
    Board,
    Gallery,
    Blog,
    ItemEditor,
    ViewEditor,
    MembershipShareDialog,
    DragDropFile
  },
  destroyed () {
    window.addEventListener('unhandledrejection', this.unhandelRejection)
  },
  methods: {
    unhandelRejection (event) {
      // the event object has two special properties:
      if (
        event.promise &&
        (event.reason.message.includes('unknown operator') ||
          event.reason.message.includes('Cannot sort on field(s)'))
      ) {
        self.errorView = true
        console.log('handled')
      }
    },
    openMembershipDialog () {
      this.$refs.membership.openMembershipDialog()
    },
    async saveQuit () {
      if (typeof this.$refs.vieweditor !== 'undefined') {
        await this.$refs.vieweditor.saveQuit()
        this.isEditing = false
      } else if (typeof this.$refs.editor !== 'undefined') {
        await this.$refs.editor.saveQuit()
        this.isEditing = false
      }
    },
    async preventNav (event) {
      await this.saveQuit()
      if (!this.isEditing) return
      event.preventDefault()
      event.returnValue = ''
    },
    openNew () {
      this.$router.push({
        name: 'list',
        params: {
          account: this.account,
          collectionID: this.collectionID,
          perspective: this.perspective,
          mode: 'new'
        }
      })
    },
    saveBookmark () {
      if (this.hasMembership) return
      let selectedcollection = Object.assign({}, this.collection.info)
      let bookmarkObject = {
        _id:
          '.membership.' +
          this.currentUser.name +
          '.' +
          selectedcollection['@dbid'],
        '@dbid': selectedcollection['@dbid'],
        account: selectedcollection.account,
        title: selectedcollection.title,
        status: 'bookmark'
      }
      this.membershipsView.put(bookmarkObject)
    },
    openEditCollection () {
      this.$parent.$parent.$parent.openEditCollection(this.collection.info)
    },
    addHeading () {
      this.$refs.layout.addNewHeading()
    },
    onDeleteView () {
      this.isViewDeleted = true
    },
    updateEditordrawer (val) {
      this.editordrawer = val
    }
  },
  async mounted () {
    var self = this
    window.addEventListener('unhandledrejection', this.unhandelRejection)
  },
  computed: {
    isOwner () {
      if (this.collection.info.account == this.repo.userID) return true
      let membership = this.collection.membership
      if (
        membership &&
        membership.roles &&
        membership.roles.includes('owner')
      ) {
        return true
      }
      return false
    },
    isPublicCollection () {
      return !(
        this.collection.info.hasOwnProperty('private') &&
        this.collection.info.private
      )
    },
    hasMembership () {
      if (this.membershipsView === null) return false
      let selectedBookmark = this.membershipsView.items.find(
        (x) =>
          x['@dbid'] == this.collection.info['@dbid'] &&
          x.hasOwnProperty('status')
      )
      return typeof selectedBookmark !== 'undefined'
    },
    collectionSync () {
      if (this.collection !== null && this.collection.isOpen) {
        return this.collection
      }
    },
    editordrawerSync () {
      return this.editordrawer
    },
    selectedViewSync: {
      // getter
      get: function () {
        return this.selectedView
      },
      // setter
      set: function (newValue) {
        this.$emit('update:selectedView', newValue)
      }
    },
    isMobile () {
      return !!(
        this.$vuetify.breakpoint.name === 'sm' ||
        this.$vuetify.breakpoint.name === 'xs'
      )
    },
    isDefaultView () {
      return this.collectionID !== 'info'
    },
    isAuth () {
      if (this.currentUser !== null) return true
      return false
    },
    /**
     * refresh key to re-render component generated a key that remind component to re-render
     */
    refreshKey: function () {
      this.isPrinting = false
      //      console.debug("refreshKey component");
      this.errorView = false
      return (
        this.$route.fullPath +
        (this.selectedView && Object.keys(this.selectedView).length !== 0
          ? this.selectedView._rev
          : '')
      )
    },
    viewInboxCollection () {
      if (this.viewInbox !== null) return this.viewInbox.collection
    },
    authorizationinbox () {
      if (this.viewInbox !== null) {
        return {
          update: true,
          comment: true,
          access: true
        }
      }
    }
  },
  watch: {},
  beforeMount () {
    window.addEventListener('beforeunload', this.preventNav)
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('beforeunload', this.preventNav)
    })
  },
  async beforeRouteUpdate (to, from, next) {
    // reset all selected class for listItems
    var li = document.querySelectorAll('.navigate')
    for (var i = 0; i < li.length; i++) {
      li[i].classList.remove('selected')
    }
    // localStorage.removeItem('selected') //remove this to hilight previously selected item
    /// 
    if (!this.isViewDeleted) {
      await this.saveQuit()
    }
    this.isViewDeleted = false
    this.editordrawer = false
    next()
  }
}
</script>
<style scoped>
.closedraweradd {
  position: fixed;
  z-index: 1;
  right: 20px;
  bottom: 15px;
}
.opendraweradd {
  position: fixed;
  z-index: 1;
  right: 20px;
  bottom: 15px;
}
.cover {
  position: absolute;
  height: 100vh;
  width: 100%;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
}
</style>
