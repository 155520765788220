<template>
  <div>
    <v-card flat class="ma-1 extend" v-if="itemSection" id="newitem">
      <div class="maincontainer ma-1" v-outside-click="saveItem">
        <DragDropFile
          :item="item"
          ref="dragdrop"
          :visible="false"
          class="simple"
          :editor="$refs.fileuploader"
          :authorization="authorization"
        >
          <v-row no-gutters>
            <v-col cols="12">
              <div class="float-left" style="width: calc(100% - 50px)">
                <div class="mt-1 d-flex">
                  <input
                    autofocus
                    autofocusheading
                    tabindex="1"
                    type="text"
                    ref="itemTitle"
                    v-model="item.title"
                    placeholder="Title here..."
                    class="custom-input-title text-left"
                  />
                </div>
                <!-- Description area -->
                <div class="mt-0 d-flex">
                  <text-area
                    :tabindex="2"
                    ref="textarea"
                    :authorization="authorization"
                    v-model="item.body"
                    :autoheight="false"
                    class="smallarea item-font pl-5"
                    :enableMarkdown="true"
                    @attachment="syncAttachment"
                    :collection="collection"
                    @submitSave="submitSave"
                  />
                </div>
              </div>
              <div class="text-center float-right" style="width: 50px">
                <div class="ma-2 ml-3 dropzoneitem">
                  <v-btn
                    class="uploadicon"
                    @click="UploadClick()"
                    icon
                    tabindex="3"
                  >
                    <v-icon>mdi-cloud-upload</v-icon></v-btn
                  >
                </div>
                <div class="dropzoneitem">
                  <div
                    class="attachment-box"
                    v-if="attachmentInfo.length > 0 || fileData.length > 0"
                  >
                    <v-icon class="small-icon primary--text"
                      >mdi-attachment</v-icon
                    ><span class="item-font">{{
                      attachmentInfo.length + fileData.length
                    }}</span>
                  </div>
                </div>
                <div @focus="autofocus" tabindex="4"></div>
              </div>
            </v-col>
          </v-row>
        </DragDropFile>
      </div>
    </v-card>
    <FileUploader
      ref="fileuploader"
      :collection="collection"
      :view="perspective == 'views' ? viewInbox : view"
      :groupBy="groupBy"
      :emptyItem="true"
      @syncfileData="syncfileData"
    />
  </div>
</template>
<script>
import Utility from '@/components/common/utils.js'
import TextArea from '@/components/common/markdownit/text-area.vue'
import Spaces from '@/components/services/spaces.js'
import FileUploader from '@/components/FileUploader.vue'
import { OutsideClick } from '@/directives/OutsideClick.js'
import _attachment from '@/components/common/attachment.js'
export default {
  name: 'HeadingItemAdd',
  components: {
    FileUploader,
    TextArea,
    DragDropFile: () => import('@/components/fileagent/DragDropFile.vue')
  },
  data: function () {
    return {
      spaces: Spaces,
      fileData: [],
      itemSection: false,
      item: { title: '', body: '', _id: Utility.uuidv4() },
      utils: Utility,
      sizeOption: '',
      thumbnails: [],
      index: 0,
      attachmentInfo: [],
      uploadAttachment: false
    }
  },
  props: {
    editor: { type: Object },
    view: Object,
    columnType: String,
    columnId: String,
    collection: Object,
    viewInbox: {
      type: Object,
      default: null
    },
    groupBy: {
      type: String,
      default: 'none'
    },
    perspective: String,
    collectionID: String,
    account: String,
    authorization: Object
  },
  directives: {
    OutsideClick
  },
  computed: {},
  mounted () {
    if (this.perspective !== 'views') {
      document.addEventListener('keydown', this.keyListener)
    }
  },
  beforeDestroy () {
    document.removeEventListener('keydown', this.keyListener)
  },
  methods: {
    quickSave () {
      if (this.item.title !== '' || this.item.body !== '') this.saveItem()
      else {
        this.itemSection = false
        Object.assign(this.$data, this.$options.data.apply(this))
      }
    },
    submitSave () {
      this.quickSave()
    },
    async keyListener (e) {
      if (e.key === 'Enter') {
        if (e.target.type !== 'textarea') {
          e.preventDefault() // present browser event trigger
          this.quickSave()
        }
      } else if (e.key === 'Escape') {
        if (this.item.title !== '' || this.item.body !== '') {
          const res = await this.$confirm(
            'There are some unsaved changes. Do you want to save',
            {
              title: `Save`,
              buttonSaveText: `Save`,
              buttonDiscardText: 'Discard',
              buttonCancelText: 'Cancel'
            }
          )
          if (res == 1) {
            this.saveItem()
          } else if (res == 0) {
            Object.assign(this.$data, this.$options.data.apply(this))
          } else {
            this.$refs.itemTitle.focus()
          }
        } else {
          Object.assign(this.$data, this.$options.data.apply(this))
        }
      }
    },
    async saveItem () {
      var isClicked = this.$refs.fileuploader.isClicked
      var uploadClick = this.$refs.fileuploader.uploadDialog
      var attachmentClick =
        typeof this.$refs.textarea === 'undefined'
          ? false
          : this.$refs.textarea.attachemntDialog
      if (isClicked) {
        isClicked = this.fileData.length > 0
      }
      if (
        !uploadClick &&
        !attachmentClick &&
        !isClicked &&
        !this.uploadAttachment
      ) {
        if (this.item.title === '' && this.item.body === '') {
          return
        }
        console.log('saved new item', this.item)
        this.item[this.columnType] = this.columnId
        if (this.columnId === 'others' || this.columnId === '') {
          delete this.item[this.columnType]
        }
        console.log('Add new item in ' + this.columnType, this.item)
        if (this.column !== null && this.groupBy !== 'none') {
          this.item[this.groupBy] = this.column._id
        }
        await this.view.put(this.item)
        // text image as attachment
        if (this.attachmentInfo.length > 0) {
          await _attachment.add(
            this.item,
            this.attachmentInfo,
            this.collection
          )
          this.attachmentInfo = []
        }
        // image as attachment
        if (this.fileData.length > 0) {
          await _attachment.add(this.item, this.fileData, this.collection)
          this.fileData = []
        }
        // if there is no items then set index 0 otherwise set index +1.
        // If new inline edit in top row then its was set -1 by addNewInlineItem() method
        var newindex = this.view.items.length === 0 ? 0 : this.index + 1
        console.debug('New item new order ' + newindex)
        await this.view.setOrder(this.item._id, newindex)
        this.highlightItem(this.item._id)
        Object.assign(this.$data, this.$options.data.apply(this))
      }
    },
    highlightItem (_id) {
      localStorage.setItem('selected', _id)
      var element = document.getElementById(_id)
      var els = document.querySelectorAll('.navigate')
      for (var i = 0; i < els.length; i++) {
        els[i].classList.remove('selected')
      }
      element.classList.add('selected')
    },
    syncfileData (fileData) {
      this.fileData.push(...fileData.fileData)
      this.sizeOption = fileData.sizeOption
      this.uploadAttachment = false
    },
    UploadClick () {
      this.uploadAttachment = true
      this.$refs.dragdrop.click()
    },
    selectedFile (selFile, sizeOption) {
      var self = this
      var file = selFile.file
      if (self.utils.isImage(file.type)) {
        if (sizeOption === 'small') {
          file = selFile.small
        } else if (sizeOption === 'medium') {
          file = selFile.medium
        }
      }
      return file
    },
    autofocus () {
      var doc = document.querySelector('[autofocusheading]')
      doc.focus()
    },
    syncAttachment (value) {
      for (var i = 0; i < value.length; i++) {
        this.attachmentInfo.push(value[i])
      }
    }
  }
}
</script>
<style scoped>
.extend {
  padding: 1px 0px;
}
.theme--dark .extend {
  background-color: #424242 !important;
}
.custom-font {
  font-size: 13px;
  font-weight: 500;
}
.is-drag-valid.is-drag-over.simple .uploadicon {
  display: inline;
}
</style>
