<template>
  <FeatureImage
    :item="item"
    :attachmentView="attachmentView"
    :collection="collection"
    :thumbSize="thumbSize"
    @hasImage="hasImage"
    :itemKey="itemKey"
    :parentKey="parentKey"
    :height="height"
    :original="original"
    :thumbnail="thumbnail"
    :areakey="areakey"
  />
</template>
<script>
import { View } from '@/collections'
import FeatureImage from '@/components/FeatureImage.vue'
export default {
  name: 'CarouselPlaceHolder',
  components: { FeatureImage },
  props: {
    areakey: String,
    item: Object,
    properties: Object,
    index: Number,
    view: Object,
    authorization: Object,
    collection: Object,
    itemKey: String,
    parentKey: String,
    thumbSize: {
      type: Object,
      default: function () {
        return { width: 0, height: 0 }
      }
    },
    height: {
      type: String,
      default: function () {
        return 'auto'
      }
    },
    original: {
      type: Boolean,
      default: function () {
        return false
      }
    },
    thumbnail: {
      type: Boolean,
      default: function () {
        return false
      }
    }
  },
  data () {
    return {
      attachmentView: null,
      showItem: false
    }
  },
  destroyed () {},
  async created () {
    this.attachmentView = await new View(this.collection).open({
      of: { attached_to: this.item._id }
    })
  },
  mounted () {},
  computed: {
    isShowItem () {
      if (!this.properties.display.fields.noThumbnail) {
        this.$emit('showItem', this.showItem)
        return this.showItem
      }

      return true
    }
  },

  methods: {
    hasImage (val) {
      this.$emit('hasImage', val)
    }
  }
}
</script>

<style scoped>
</style>
