<template>
  <div class="filter">
    <v-btn x-small class="ma-2" color="primary" fab @click="add(0)" v-show="inputs.length===0">
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    <div v-for="(input,k) in inputs" :key="k">
      <v-row>
        <v-col cols="12">
          <v-select
            :items="rules"
            item-text="title"
            item-value="rules"
            return-object
            v-model="selectedrule[k]"
            @change="delete inputs[k].selectedOperator;delete inputs[k].time"
          />
        </v-col>
        <v-col cols="12">
          <div v-if="selectedrule[k] && filterOperators[selectedrule[k].operator]">
            <v-select
              :items="filterOperators[selectedrule[k].operator]"
              item-text="description"
              item-value="filterOperators[selectedrule[k].operator]"
              return-object
              v-model="inputs[k].selectedOperator"
              @change="updateValue"
            />
          </div>
        </v-col>
        <v-col cols="12">
          <div v-if="input.selectedOperator && (input.selectedOperator.type==='text')">
            <v-text-field v-model="inputs[k].value" @change="updateValue" />
          </div>
          <div v-else-if="input.selectedOperator && (input.selectedOperator.chooser==='date-time')">
            <v-datetime-picker v-model="inputs[k].value" @input="updateValue"></v-datetime-picker>
          </div>
          <div
            v-else-if="input.selectedOperator && (input.selectedOperator.chooser==='time-length')"
          >
            <v-row style="margin-top: -2px !important;">
              <v-col cols="12">
                <v-select
                  :items="times"
                  item-text="title"
                  item-value="value"
                  return-object
                  v-model="inputs[k].time"
                  @change="updateValue"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field v-model="inputs[k].value" @change="updateValue" />
              </v-col>
            </v-row>
          </div>
          <div
            v-else-if="selectedrule[k] && (selectedrule[k].type==='string' && Array.isArray(selectedrule[k].choices) && selectedrule[k].choices.length>0)"
          >
            <v-select
              :items="selectedrule[k].choices"
              item-text="title"
              item-value="value"
              v-model="inputs[k].value"
              @change="updateValue"
            />
          </div>
          <div
            v-else-if="selectedrule[k] && (selectedrule[k].type==='string' && typeof selectedrule[k].choices === 'string')"
          >
            <v-select
              :items="getItems(selectedrule[k].choices,k)"
              item-text="title"
              item-value="value"
              v-model="inputs[k].value"
              @change="updateValue"
            />
          </div>
        </v-col>
        <v-col cols="12">
          <v-btn
            x-small
            color="red"
            fab
            @click="remove(k)"
            v-show="k || ( !k && inputs.length >= 1)"
            class="mt-2"
          >
            <v-icon>mdi-minus</v-icon>
          </v-btn>
          <v-btn
            x-small
            class="mt-2 ml-2"
            color="primary"
            fab
            @click="add(k)"
            v-show="k == inputs.length-1"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <p class="d-none">{{value}}</p>
  </div>
</template>

<script>
import filterRule from '@/components/json/filterRule.json'
import filterOperators from '@/components/json/filterOperators.json'
import lodash from 'lodash'
import moment from 'moment'
export default {
  data: function () {
    return {
      inputs: [],
      selectedrule: [],
      rules: lodash.sortBy(filterRule, 'key'),
      filterOperators: filterOperators,
      times: [
        { title: 'Days', value: '$days' },
        { title: 'Weeks', value: '$weeks' },
        { title: 'Months', value: '$months' },
        { title: 'Years', value: '$years' }
      ]
    }
  },
  props: {
    value: {
      type: Object
    },
    view: {
      type: Object
    }
  },
  created () {
    const entries = Object.entries(this.value)
    var i = 0
    var j = 0
    for (const [filterKey, filterValue] of entries) {
      var rule = this.rules.find(function (item, i) {
        return item.key === filterKey
      })
      if (typeof rule !== 'undefined') {
        var input = this.generatformInputJSON(filterValue, rule)
        this.inputs[i++] = input
        this.selectedrule[j++] = rule
      }
    }
  },
  computed: {},
  methods: {
    generatformInputJSON (filterValue, rule) {
      if (rule && rule.hasOwnProperty('operator')) {
        /* it may happens key is null but it has operator like
          {
            "description": "Is Exactly",
            "operator": null,
            "type": "text"
            }
        */
        var key = [null]
        if (typeof filterValue === 'object') {
          key = Object.keys(filterValue)
        }

        var values = Object.values(filterValue)
        var operator = this.getOperator(rule.operator, key[0])
        if (typeof values[0] === 'object') {
          var subkey = Object.keys(values[0])
          var subvalue = Object.values(values[0])
          var times = this.times.find(function (item, i) {
            return item.value === subkey[0]
          })
          return {
            selectedOperator: operator,
            value: subvalue[0],
            time: times
          }
        } else {
          return {
            selectedOperator: operator,
            value:
              operator &&
              operator.hasOwnProperty('operator') &&
              operator.chooser === 'date-time'
                ? moment(values[0], 'YYYY-MM-DDTHH:mm:ssZ').format(
                  'YYYY-MM-DD HH:mm'
                )
                : key[0] === null
                  ? filterValue
                  : values[0]
          }
        }
      }
      return { value: filterValue }
    },
    getOperator (operator, operatorKey) {
      return this.filterOperators[operator].find(function (item, i) {
        return item.operator === operatorKey
      })
    },
    updateValue () {
      this.$emit('sync', this.generateFilter())
    },
    add (index) {
      this.inputs.push({})
    },
    remove (index) {
      this.inputs.splice(index, 1)
      this.selectedrule.splice(index, 1)
      this.updateValue()
    },
    generateFilter () {
      var filterRule = []
      var newrule = {}
      for (var i = 0; i < this.selectedrule.length; i++) {
        // if no selected rule skip
        if (this.selectedrule[i] === 'undefined') continue
        if (
          this.inputs[i].hasOwnProperty('selectedOperator') &&
          this.inputs[i].selectedOperator.operator
        ) {
          if (this.inputs[i].hasOwnProperty('time') && this.inputs[i].time) {
            // generates { "createdAt": { "$newerThan": { "$days": 15 } }
            var subrule = {}
            subrule[this.inputs[i].time.value] = this.inputs[i].value
            var containsrule = {}
            containsrule[this.inputs[i].selectedOperator.operator] = subrule
            newrule[this.selectedrule[i].key] = containsrule
          } else {
            // generate filter option { "createdAt": {"$olderThanDays": 20} }
            var containsrule = {}
            containsrule[this.inputs[i].selectedOperator.operator] =
              this.inputs[i].selectedOperator.chooser === 'date-time'
                ? moment
                  .utc(this.inputs[i].value)
                  .format('YYYY-MM-DDTHH:mm:ssZ')
                : this.inputs[i].value
            newrule[this.selectedrule[i].key] = containsrule
          }
        } else {
          newrule[this.selectedrule[i].key] = this.inputs[i].value
        }
      }
      return newrule
    },
    getItems (key, k) {
      this.selectedrule[k].choices = this.view.getChoices('', key)
    },
    getWebContent (key) {}
  }
}
</script>
<style scoped>
.filter .col {
  margin: 0;
  padding: 0 20px;
}
</style>
